<template>

  <div class="h-auto w-full text-base md:text-lg xl:text-xl 2xl:text-3xl 
              font-Open+Sans text-gray-500 pl-2 pt-1 mb-8 mr-4">
    <div> 
      <div class="grid grid-cols-6">
        <div class="col-span-1 my-auto text-right"> Parcel:
        </div>
        <div class="col-span-5 ml-3 mr-0">
          <muni-select
            @change="getBlockLots">
          </muni-select>
        </div>
        <div class="col-span-1 text-right md:mt-3"> 
          <label for="blocks"
            class="inline"
            :class="{'text-gray-500': block_lots,
                  'text-gray-200': !block_lots}">
            Block:
          </label>
        </div>
        <div class="col-span-5 ml-3 mr-0 mb-1 mt-1 md:mt-3">
          <div class="h-8 bg-transparent flex flex-col items-center 
                justify-center"
            v-show="getting_blocks">
            <div class="flex w-7 h-7 rounded-full bg-blue-400 
                  bg-opacity-75 border border-white">
              <div class="bg-transparent text-white text-right 
                    m-auto text-xs md:text-sm animate-spin pr-2">
                --&nbsp;
              </div>
            </div> 
          </div>
          <select id='blocks'
              class="h-8 w-5/6 px-2 rounded border-gray-200 
                border shadow-sm 
                appearance-none
                text-base" 
              :disabled="!block_lots"
              :class="{'bg-gray-50': !block_lots}"
              @change="getLots($event)"
              v-show="!getting_blocks">
            <option selected></option>
            <option v-for="block in block_lots"
                :key="block.block"
                :value="block.block"
                class="text-gray-700">
              {{block.block}}
            </option>
          </select>
        </div>
        <div class="col-span-1 my-auto md:my-0 md:mt-2 text-right "> 
          <label for="lots" 
            class="inline"
            :class="{'text-gray-500': lots,
                  'text-gray-200': !lots}">
            Lot:
          </label>
        </div>
        <div class="col-span-5 ml-3 mr-0 md:mt-2 "> 
          <select id='lots'
              class="h-8 w-5/6 px-2 rounded border 
                border-gray-200 shadow-sm 
                appearance-none
                text-base" 
              :class="{'bg-gray-50': !lots}"
              :disabled="!lots"
              @change="getPin($event)">
              :disabled="!lots" >
            <option value="" selected></option>
            <option v-for="lot in lots"
                :key="lot.l"
                :value="lot.l"
                class="text-gray-700">
              {{lot.l}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

</template>
    
<script>
  import axios from 'axios';
  import MunicipalSelect from './MunicipalSelect.vue';
  import search_parcels_svg from "../assets/img/icons/search_parcels.svg"

  export default {

    name: 'ParcelSearch',

    components: {
      "muni-select": MunicipalSelect
    },

    data(){

      return{
        municipalities: null,
        municipal_code: null,
        block_lots: null,
        lots: null,
        block: null,
        getting_blocks: false,
        lot: null,
        pams_pin: null,
        centroid: null,
        search_parcels_svg
      }
    },

    emits: ['parcel'],

    watch:{
      pams_pin: function(){
        if(!this.pams_pin){
          return;
        }

        const action = 'get_parcel_centroid';

        let url = '';
        url += 'https://';
        url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
        url += 'default/data-retriever?';
        url += `action=${action}&`;
        url += `pams_pin=${this.pams_pin}`;

        axios
        .post(url)
        .then(response => {
          
          if(Object.prototype.hasOwnProperty.call(
                        response.data,'message')){
            console.log(response.data['message']);
          }else{
            this.centroid = response.data;
          }
        })

      },

      centroid: function(){
        const stamp = Date.now();
        const obj = {"source": "parcel_search",
               "stamp": stamp,
               "data": {"coordinates": this.centroid,
                   "feature_id": this.pams_pin}};
        
        this.$emit("parcel", obj);
      }
    },

    methods: {

      clearSelects(){
        this.block_lots = null;
        this.lots = null;
      },

      getPin($event){
        if(!this.lot && !this.block && !this.municipal_code){
          return;
        }
        this.lot= $event.target.value;

        const parts = [this.municipal_code, this.block, 
                this.lot.replace(" ","_")];
        this.pams_pin = parts.join('_');

        this.pushToAnalytics(this.pams_pin);

      },

      getMuniList(){
        const action = 'get_muni_list';

        let url = '';
        url += 'https://';
        url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
        url += 'default/data-retriever?';
        url += `action=${action}`;

        axios
        .post(url)
        .then(response => {
          
          if(Object.prototype.hasOwnProperty.call(
                        response.data,'message')){
            console.log(response.data['message']);
          }else{
            this.municipalities = response.data;
          }
        })
      },

      getBlockLots(muni){
        if(!muni){
          this.clearSelects();
          return;
        }
        if(!muni.hasOwnProperty('muni')){
          this.clearSelects();
          return;
        }
        this.getting_blocks = true;

        this.municipal_code = muni.muni;
        this.block = null;
        this.block_lots = null;
        this.lots  = null;
        this.lot= null;

        const action = 'get_muni_block_lots';

        let url = '';
        url += 'https://';
        url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
        url += 'default/data-retriever?';
        url += `mun_code=${this.municipal_code}&`
        url += `action=${action}&`;

        axios
        .post(url)
        .then(response => {
          
          if(Object.prototype.hasOwnProperty.call(
                        response.data,'message')){
            console.log(response.data['message']);
          }else{
            this.block_lots = response.data;
          }
        })
        .then(()=>{this.getting_blocks = false;});
      },

      getLots($event){
        if(!$event.target.value){
          return;
        }
        this.lot = null;
        this.block = $event.target.value;
        this.lots = 
          this.block_lots.find(
            ({ block }) => block === this.block
          ).lots;
      },

      pushToAnalytics(pams_pin){
        dataLayer.push({
          'event': 'parcel_search',
          'search_pams_pin': pams_pin
        });
      }
    },

    mounted(){
      this.getMuniList();
    }
  }

</script>
<template>

    <div class="w-full pb-10 md:pb-0">
      <div class="h-8 inline w-full md:ml-1"> 
        <select id='menu_parcel_search'
            class="h-9 w-5/6 md:w-5/6 px-2 rounded border-gray-200 float-left
              border shadow 
              appearance-none
              text-base"
            :class="selected? 'text-gray-900': 'text-gray-400'"
            v-model="selected"
            @change="emitChange">
          <option value='' 
                  class="text-left pr-0.5">
               &#8597; Municipality
          </option>
          <option v-for="muni in municipalities"
              :key="muni.mun"
              :value="muni.mun">
            {{muni.name}}
          </option>
        </select> 
        <img :src="trash_hover_svg" class="w-6 xl:w-8 pt-2 pl-2 float-left"
            alt="Deselects the selected municipality" 
            @mouseover="swapImage($event.target, trash_svg)"
            @mouseleave="swapImage($event.target, trash_hover_svg)"
            @click="removeFilter">
      </div>
    </div>

</template>

<script>

  import axios from 'axios';
  import trash_svg from "../assets/img/icons/trash.svg"
  import trash_hover_svg from "../assets/img/icons/trash_hover.svg"

  export default {

    name: 'MunicipalSelect',

    props: ['border'],

    emits: ['change'],

    data(){

      return{
        geojson: null,
        municipalities: null,
        selected: '',
        trash_svg,
        trash_hover_svg
      }
    },

    methods: {

       getGeojson(code){
        if(!code){
          this.geojson = null;
          return;
        }

        const action = 'get_muni_geojson';

        let url = '';
        url += 'https://';
        url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
        url += 'default/data-retriever?';
        url += `action=${action}`;
        url += `&mun_code=${code}`;

        axios
        .post(url)
        .then(response => {
          
          if(Object.prototype.hasOwnProperty.call(
                        response.data,'message')){
            console.log(response.data['message']);
            this.selected = '';
          }else{
            this.geojson = response.data[0];
            this.selected = code;
            this.$emit('change', {
              'muni':code, 
              'geojson': this.geojson
            });
          }
        });


      },

      emitChange(e){

        if(e.target.value == ''){
          this.removeFilter()
          return;
        }

        if(this.border){
          this.getGeojson(e.target.value);
        }else{
           this.$emit('change', {
              'muni':e.target.value, 
              'geojson': this.geojson
            }); 
        }
      },

      getMuniList(){
        const action = 'get_muni_list';

        let url = '';
        url += 'https://';
        url += 'gspr34zib0.execute-api.us-east-1.amazonaws.com/';
        url += 'default/data-retriever?';
        url += `action=${action}`;

        axios
        .post(url)
        .then(response => {
          
          if(Object.prototype.hasOwnProperty.call(
                        response.data,'message')){
            console.log(response.data['message']);
          }else{
            this.municipalities = response.data;
          }
        })
      },

      removeFilter(){
        this.$emit('change');
        this.selected = '';
      },

      swapImage(element,src){
        element.src = src;
      },
    },

    mounted(){
      this.getMuniList();
    }
  }

</script>

<style>

</style>
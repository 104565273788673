<template>
 
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem] pl-0 mb-1"
       :class="{'bg-ref_comp_bg_closed': !showing,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left pt-2 pb-1 rounded-[0.4375rem]"
         :class="{'bg-ref_comp_bg_closed': !showing,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center text-ref_text_min"
        :class="{'opacity-100': showing,
                'opacity-0': !showing}">
        &#10003;
      </div>
      <div class="col-span-7 mr-0 shadow"> 
        <select
            title="Display alternative base imagery"
            class="h-7 xl:h-9 2xl:h-12 w-full px-2 rounded border-gray-200 
                   text-sm xl:text-lg 2xl:text-xl border shadow-sm 
                    inline text-gray-400"
            @change="updateLayerId($event)"
            v-model="layer_id" >
          <option value="none" selected>
            Select
          </option>
          <option  v-for="(value, key) in services" :key="key"
              :value="value"
              class="text-gray-700">
            {{key}}
          </option>
        </select>
      </div>
      <div class="col-span-4 col-start-8 text-xs text-ref_text_min hover:underline
                  pl-4 cursor-pointer"
           @click="clearOverlay"
           v-show="showing">
            <span class="align-text-bottom leading-6">Remove</span>
      </div>
    </div>
  </div>

</template>

<script>

  export default {

      name: "ImagerySelection",

      data(){
        return{
          data_error: '',
          data_name: 'soil_descriptions',
          hover_meta: false,
          hover_source: false,
          hover_title: false,
          label: 'njdep',
          layer_id: 'none',
          overlay: {
            'source': {
              'name': 'njdep',
              'type': 'raster',
              'tiles': [
                'https://img.nj.gov/imagerywms/Natural2015?bbox=' +
                '{bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1' +
                '&request=GetMap&srs=EPSG:3857&transparent=true&width=256&'+
                'height=256&layers=Natural2015'
              ],
              'tileSize': 256,
              'minzoom': 0,
              'maxzoom': 21
              },
            'layer': {
              'id': 'njdep-imagery',
              'type': 'raster',
              'source': 'njdep',
              'paint': {}
            }
          },

          services: {
            '2020 Natural Color': 'Natural2020',
            '2020 Infrared': 'Infrared2020',
            '2019 Natural Color': 'Natural2019',
            '2017 Natural Color': 'Natural2017',
            '2015 Natural Color': 'Natural2015',
            '2015 Infrared': 'Infrared2015',
            '2013 Natural Color': 'Natural2013',
            '2012 Natural Color': 'Natural2012',
            '2012 Infrared': 'Infrared2012',
            '2010 Natural Color': 'Natural2010',
            '2007 Natural Color': 'Natural2007',
            '2007 Infrared': 'Infrared2007',
            '2006 Natural Color': 'Natural2006',
            '2002 Infrared': 'Infrared2002',
            '1995 Infrared': 'Infrared1995',
            '1980-1987 Infrared': 'Infrared1980-1987',
            '1977 Tidal Lands': 'Tidelands1977',
            '1970 Wetlands': 'Wetlands1970',
            '1930s Black & White': 'BlackWhite1930',
            '1920 Coastal': 'Coastal1920'
          },
          showing: false
        }
      },

    props:['basemap_changed','layers_to_load',  'map', 'map_loaded', 'reset', 
            'style_change', 'zoom'],

    emits: ['add', 'mounted', 'remove'],

    watch:{

      basemap_changed: function(){
        this.clearOverlay();
      },

      map_loaded: function(){
        Object.values(this.services).forEach(
          item => {
            if(this.layers_to_load.includes(item)){
              this.layer_id = item;
              this.addOverlay();
              this.showing = true;
              return;
            }
          }
        )
        this.$emit('mounted', this.overlay.layer.id);

        if(this.layers_to_load.includes('this.overlay.layer.id')){
          this.layer_id
          this.addLayer();
          this.showing = true;
        }
      },

      reset: function(){
          this.clearOverlay();
      }
    },

    methods:{

      addLayer(){
        if(this.map.getLayer(this.overlay.layer.id)){
          return;
        }
        
        this.map.addLayer({
          'id': this.overlay.layer.id,
          'type': this.overlay.layer.type,
          'source': this.overlay.source.name, 
          'paint': this.overlay.layer.paint
        }, 'features');
        if(this.map.getLayer('feature-raster')){
          this.map.moveLayer(this.overlay.layer.id, 'feature-raster');
        }
      },

      addOverlay(){
        this.addSource();
        this.addLayer();
        this.showing = true;
        this.$emit('add', this.overlay.layer.id);
      },

      addSource(){
        if(this.map.getSource(this.overlay.source.name)){
            return;
        }
          
        this.map.addSource(this.overlay.source.name, this.overlay.source);
      },

      clearOverlay(){
        this.layer_id = 'none';
        this.removeOverlay();
      },

      createTileUrl(){
        const prefix = 'https://img.nj.gov/imagerywms/';
        const suffix = '?bbox={bbox-epsg-3857}&format=image/png&service=WMS' + 
                     '&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent' + 
                     '=true&width=256&height=256&layers=';
        const url = prefix + this.layer_id + suffix + this.layer_id;

        this.overlay.source.tiles[0] = url;
        
      },

      removeOverlay(){
        if (this.map.getLayer(this.overlay.layer.id)) {
          this.map.removeLayer(this.overlay.layer.id);
          this.$emit('remove', this.overlay.layer.id);
        }
        if (this.map.getSource(this.overlay.source.name)) {
          this.map.removeSource(this.overlay.source.name);
        }
        this.$emit('remove', this.overlay.layer.id);
        this.showing = false;
      },

      updateLayerId(e){
        this.removeOverlay();
        if(e.target.value != 'none'){
          this.layer_id = e.target.value;
          this.createTileUrl();
          this.addOverlay();
        }else{
          this.layer_id = 'none';
        }
      }
    }
  }

</script>

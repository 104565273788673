<template>

  <div class="bg-white font-Open+Sans overflow-y-scroll"
        :style="{'height': `${panel_height}px`}">
    <form id="filter-form">
    <div class="pt-6 pb-0 text-center text-2xl text-slate-500"
         v-if="!zoom_threshold_met">Enabled at higher zoom
    </div>
    <div class="pt-4 pb-4 md:pb-5 xl:pb-7 2xl:pb-10">
      <h2 class="inline float-left w-4/5 pl-4 text-base md:text-lg lg:text-xl 
                 xl:text-2xl 2xl:text-3xl"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-400': !zoom_threshold_met}">
        Blueprint Model Scores:
      </h2>
    </div>

    <div class="sticky top-0 bg-white h-4 z-50">
        <input class="inline float-right w-1/5 pr-4 mt-2 text-sm lg:text-base 
                      2xl:text-2xl text-blue-500 cursor-pointer hover:underline"
                type="button" @click="reset" value="Reset">
    </div>
    <div class="pl-10 mt-4 text-left text-sm md:text-base xl:text-xl
                2xl:text-2xl"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
      <label class="inline float-left w-1/4 2xl:w-2/5" 
              for="filter_agricultural_model"
              title="Set the minimum agricultural score of shown parcels."
              >Agricultural</label>
      <input type="range"
              min="0" max="10" step="1"
              class="w-1/5"
              v-model="filters[3]"
              id="filter_agricultural_model"
              :disabled="!zoom_threshold_met">
        <span class="text-base 2xl:text-xl ml-2"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
          {{filters[3]}} / 10
        </span>
    </div>

    <div class="pl-10 mt-4 text-left text-sm md:text-base xl:text-xl
                2xl:text-2xl"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
      <label class="inline float-left w-1/4 2xl:w-2/5" 
              for="filter_ecological_model"
              title="Set the minimum Ecological score of shown parcels.">
              Ecological
      </label>
      <input type="range"
              min="0" max="10" step="1"
              class="w-1/5"
              v-model="filters[4]"
              id="filter_ecological_model"
              :disabled="!zoom_threshold_met">
        <span class="text-base 2xl:text-xl ml-2"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
          {{filters[4]}} / 10
        </span>
    </div>

    <div class="pl-10 mt-4 text-left text-sm md:text-base xl:text-xl
                2xl:text-2xl"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
      <label class="inline float-left w-1/4 2xl:w-2/5" 
              for="filter_water_model"
              title="Set the minimum Water score of shown parcels.">
              Water
      </label>
      <input type="range"
              min="0" max="10" step="1"
              class="w-1/5"
              v-model="filters[5]"
              id="filter_water_model"
              :disabled="!zoom_threshold_met">
        <span class="text-base 2xl:text-xl ml-2"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
          {{filters[5]}} / 10
        </span>
    </div>

    <div class="pt-6 pb-4 md:pb-5 xl:pb-7 2xl:pb-10">
      <h2 class="inline float-left w-4/5 pl-4 text-base md:text-lg lg:text-xl 
                 xl:text-2xl 2xl:text-2xl"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
        Parcels:
      </h2>
    </div>
    <div class="py-1 text-left pl-10 mt-4 text-sm  md:text-base xl:text-xl
             text-gray-500 mx-auto my-auto"
          :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
      <label class="pr-1 text-sm md:text-base xl:text-xl 
                    2xl:text-2xl align-middle"  
            for="filter-min-feature-size"
            title="Set the minimum acreage of shown parcels."
              :class="{'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Minimmum Size
      </label>
      <!-- <select-box
          v-bind:id="filter-min-feature-size"
          v-bind:name="filter-min-feature-size"
          v-bind:model="filters[1]"
          v-bind:disabled="!zoom_threshold_met"
          v-bind:conditional_classes="{'cursor-not-allowed': !zoom_threshold_met,'cursor-pointer': zoom_threshold_met}"
          v-bind:start="0"
          v-bind:end="50"
          v-bind:increment="1">
       </select-box>   -->
      <select 
             id="filter-min-feature-size"
             name="filter-min-feature-size"
             v-model="filters[1]"
             class="border border-slate-200 align-middle" 
             :class="{'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}"
             :disabled="!zoom_threshold_met">
        <option value="0"></option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="30">30</option>
        <option value="35">35</option>
        <option value="40">40</option>
        <option value="45">45</option>
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
        <option value="150">150</option>
        <option value="200">200</option>
        <option value="250">250</option>
        <option value="300">300</option>
        <option value="350">350</option>
      </select> 
        <label class="text-base 2xl:text-xl ml-2  align-bottom" 
               for="filter-min-feature-size">
          Acres
        </label>
    </div>

    <div class="mt-4 mx-auto pt-1 2xl:pt-6 pb-0.5 2xl:pb-6 pr-4 text-center 
                text-base md:text-lg xl:text-2xl 2xl:text-3xl cursor-pointer
                hover:underline bg-slate-100"
          :class="{'text-blue-400': zoom_threshold_met,
                  'text-gray-300': !zoom_threshold_met}">
      <span class="inline pr-2">&#8597;</span>
      <h2 class="inline"
          id="filter_advanced"
          @click="show_advanced = !show_advanced">Advanced Filters
      </h2>
    </div>

    <div class="overflow-y-scroll"
          :class="show_advanced ? 'block' : 'hidden'">

      <div class="pt-2 pl-4 text-left italic text-gray-400 text-base w-full
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mx-auto">
        <h3 class="">Ecological</h3>
      </div>

      <div class="pt-2 pl-10 2xl:pb-4 text-left italic text-gray-400 text-base
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl mx-auto">
        <h3 class="my-1 -ml-3">Rare Species and Natural Communities</h3>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_landscape_rank"
              title="Set the minimum Landscape V3.3 rank of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
                Landscape Rank
        </label>
        <input type="range"
                min="0" max="5" step="1"
                id="filter_landscape_rank"
                class=" w-1/5"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[24]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[24]}} / 5
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_landscape_species"
              title="Set the minimum Landscape V3.3 species score of shown 
                      parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Landscape Species Score
        </label>
        <input type="range"
                min="0" max="5" step="1"
                class="w-1/5"
                id="filter_landscape_species"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[25]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[25]}} / 5
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_vernal_habitat"
              title="Set the minimum vernal habitat score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Vernal Habitat Score
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_vernal_habitat"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[26]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[26]/10}} / 1
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_priority_site"
              title="Set the minimum Natural Heritage Priority Site score of 
                      shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Priority Site Score
        </label>
        <input type="range"
                min="0" max="30" step="3"
                class="w-1/5"
                id="filter_priority_site"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[27]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[27]/10}} / 3
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_habitat_condition"
              title="Set the minimum Nature's Network Habitat Condition score of 
                      shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Habitat Condition Score
        </label>
        <input type="range"
                min="0" max="30" step="3"
                class="w-1/5"
                id="filter_habitat_condition"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[28]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[28]/10}} / 3
          </span>
      </div>

      <div class="pt-2 pl-10 text-left italic text-gray-400 text-base 
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl mx-auto">
        <h3 class="my-1 2xl:my-8 -ml-3 float-left w-4/5">Climate Change
                   Resilience</h3>
      </div>
      
      <div class="w-full bg-white py-1 text-left pl-10 clear-left
                  font-Open+Sans text-base text-gray-500 mx-auto">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl"  
              for="filter_combined_resilience"
              title="Set the minimum Nature Conservancy Resilience score of
                      shown parcels. (Calculated from Terrestrial and Coastal 
                      Resilience scores in parcels where both are present)."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Combined Resilience Score
        </label>
        <input type="range"
                min="0" max="5" step="1"
                class="w-1/5"
                id="filter_combined_resilience"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[31]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[31]}} / 5
          </span>
      </div>
      
      <div class="w-full bg-white py-1 2xl:py-2 pl-16 text-left 
                  font-Open+Sans text-base text-gray-400 mx-auto">
        <label class="pr-1 pl-1.5 text-sm md:text-base xl:text-xl 2xl:text-2xl"
              for="filter_terrestrial_resilience"
              title="Set the minimum Nature Conservancy Terrestrial Resilience
                      score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Terrestrial Resilience Score
        </label>
        <input type="range"
                min="0" max="5" step="1"
                class="w-1/5"
                id="filter_terrestrial_resilience"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[29]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[29]}} / 5
          </span>
      </div>
      
      <div class="w-full bg-white py-1 2xl:py-2 pl-16 text-left 
                  font-Open+Sans text-base text-gray-400 mx-auto">
        <label class="pr-1 pl-1.5 text-sm md:text-base xl:text-xl 2xl:text-2xl"
              for="filter_coastal_resilience"
              title="Set the minimum Nature Conservancy Coastal Resilience
                      score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Coastal Resilience Score
        </label>
        <input type="range"
                min="0" max="5" step="1"
                class="w-1/5"
                id="filter_coastal_resilience"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[30]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[30]}} / 5
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_marsh_mmigration"
              title="Set the minimum Nature Conservancy Marsh Migration Space
                      (6 ft.) score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Marsh Migration Space Score
        </label>
        <input type="range"
                min="0" max="20" step="2"
                class="w-1/5"
                id="filter_marsh_mmigration"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[32]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[32]/10}} / 2
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="tcmin"
              title="Set the minimum Concentrated Flow score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
            Terrestrial Connectivity Score
        </label>
        <input type="range"
                min="0" max="20" step="2"
                class="w-1/5"
                id="filter_terrestrial_connectivity"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[33]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[33]/10}} / 2
          </span>
      </div>

      <div class="pt-2 pl-10 text-left italic text-gray-400 text-base 
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl mx-auto">
        <h3 class="my-1 2xl:my-8 -ml-3 float-left w-4/5">Habitat Connectivity
        </h3>
      </div>
    
      <div class="w-full bg-white py-1 text-left pl-10 clear-left
                  font-Open+Sans text-base text-gray-500 mx-auto">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl"  
              for="filter_model_chanj"
              title="Set the minimum Model CHANJ score of shown parcels. (The
                    Model CHANJ score is derived from the Core, Corridor and 
                    Stepping Stone CHANJ scores.)"
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
                Model CHANJ Score
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_model_chanj"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[34]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[34]}} / 10
          </span>
      </div>

      <div class="w-full bg-white py-1 2xl:py-2 pl-16 text-left 
                  font-Open+Sans text-base text-gray-400 mx-auto">
        <label class="pr-1 pl-1.5 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_chanj_core"
              title="Set the minimum Model CHANJ Core score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
                CHANJ <span class="italic">Core Score</span>
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_chanj_core"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[35]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[35]}} / 10
          </span>
      </div>

      <div class="w-full bg-white py-1 2xl:py-2 pl-16 text-left 
                  font-Open+Sans text-base text-gray-400 mx-auto">
        <label class="pr-1 pl-1.5 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_chanj_corridor"
              title="Set the minimum Model CHANJ Corridor score of shown 
                    parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
                CHANJ <span class="italic">Corridor Score</span>
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_chanj_corridor"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[36]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[36]}} / 10
          </span>
      </div>
  
      <div class="w-full bg-white py-1 2xl:py-2 pl-16 text-left 
                  font-Open+Sans text-base text-gray-400 mx-auto">
        <label class="pr-1 pl-1.5 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_chanj_stepping_stone"
              title="Set the minimum Model CHANJ Stepping Stone score of shown 
                    parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
                CHANJ <span class="italic">Stepping Stone</span> Score
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_chanj_stepping_stone"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[37]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[37]}} / 10
          </span>
      </div>
  
      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_forest_core"
              title="Set the minimum Forest Core score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Forest Core Score
        </label>
        <input type="range"
                min="0" max="30" step="3"
                class="w-1/5"
                id="filter_forest_core"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[38]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[38]/10}} / 3
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_terrestrial_core_connector"
              title="Set the minimum Nature's Network Habitat Connectivity score 
                    of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Terrestrial Core/Connectors Score
        </label>
        <input type="range"
                min="0" max="5" step="1"
                class="w-1/5"
                id="filter_terrestrial_core_connector"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[39]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[39]}} / 5
          </span>
      </div>

      <div class="pt-2 pl-10 text-left italic text-gray-400 text-base 
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mx-auto">
        <h3 class="my-1 2xl:my-8 -ml-3 float-left w-4/5">Water</h3>
      </div>

      <div class="w-full bg-white py-1 text-left pl-10 clear-left
                  font-Open+Sans text-base text-gray-500 mx-auto">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl"  
              for="filter_headwaters"
              title="Set the minimum Headwaters (catchments with 1st order 
                      streams) score. "
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Headwaters Score
        </label>
        <input type="range"
                min="0" max="20" step="2"
                class="w-1/5"
                id="filter_headwaters"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[7]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[7]/10}} / 2
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_floodplains"
              title="Set the minimum Nature Conservancy Active River Area (ARA)
                    score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Floodplain Score
        </label>
        <input type="range"
                min="0" max="20" step="2"
                class="w-1/5"
                id="filter_floodplains"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[20]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[20]/10}} / 2
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_impervious_surface"
              title="Set the minimum Impervious Surface score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Impervious Surface Score
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_impervious_surface"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[21]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[21]/10}} / 1
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_ground_water_recharge"
              title="Set the minimum Ground Water Recharge score of 
                    shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Ground Water Recharge
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_ground_water_recharge"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[22]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[22]/10}} / 1
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_wetlands"
              title="Set the minimum Wetlands score of shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Wetlands Score
        </label>
        <input type="range"
                min="0" max="10" step="1"
                class="w-1/5"
                id="filter_wetlands"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[23]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[23]/10}} / 1
          </span>
      </div>

      <div class="pt-2 pl-10 text-left italic text-gray-400 text-base
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mx-auto">
        <h3 class="my-1 2xl:my-8 -ml-3 float-left w-4/5">Parcel (Agriculture)
        </h3>
      </div>

      <div class="w-full bg-white py-1 text-left pl-10 clear-left
                  font-Open+Sans text-base text-gray-500 mx-auto">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl"  
              for="filter_agricultural_threshold"
              title="Minimum Agricultural Threshold met in shown parcels."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Meets Agricultural Threshold
        </label>
        <input type="checkbox"
            id="filter_agricultural_threshold"
            class="align-middle"
            :class="{'cursor-not-allowed': !zoom_threshold_met,
                    'cursor-pointer': zoom_threshold_met}"
            :disabled="!zoom_threshold_met"
            v-model='filters[8]'>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_adjacent_preserved_farm"
              title="Parcel is adjacent to a preserved farm."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Adjacent to Preserved Farm
        </label>
        <input type="checkbox"
            id="filter_adjacent_preserved_farm"
            class="align-middle"
            :class="{'cursor-not-allowed': !zoom_threshold_met,
                    'cursor-pointer': zoom_threshold_met}"
            :disabled="!zoom_threshold_met"
            v-model='filters[10]'>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_infill_farm"
              title="Parcel qualifies as an infill farm."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              Infill Farm
        </label>
        <input type="checkbox"
            id="filter_infill_farm"
            class="align-middle"
            :class="{'cursor-not-allowed': !zoom_threshold_met,
                    'cursor-pointer': zoom_threshold_met}"
            :disabled="!zoom_threshold_met"
            v-model='filters[11]'>
      </div>      
      
      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_agricultural_production"
              title="Set the minimum % of parcel in agricultural prodcution."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % in Agricultural Production
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_agricultural_production"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[9]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[9]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_half_mile_preserved"
              title="Set the minimum % of parcel that's within 1/2 mile of 500 
                    preserved acres."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Within .5 Mi. of 500 Presv. Acres
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_half_mile_preserved"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[12]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[12]}}
          </span>
      </div>

      <div class="pt-2 pl-10 text-left italic text-gray-400 text-base 
                  md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl mx-auto">
        <h3 class="my-1 2xl:my-8 -ml-3 float-left w-4/5">Soil (Agriculture)</h3>
      </div>

      <div class="w-full bg-white py-1 pl-10 2xl:pl-14 text-left clear-left
                  font-Open+Sans text-base text-gray-500 mx-auto">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl"  
              for="filter_prime_soil"
              title="Set the minimum % of prime soil in the parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Prime Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_prime_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[13]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[13]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_statewide_soil"
              title="Set the minimum % of statewide soil in the parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Statewide Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_statewide_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[14]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[14]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_statewide_soil_drained"
              title="Set the minimum % of statewide soil (if drained) in the 
                      parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Statewide Soil (if drained)
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_statewide_soil_drained"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[15]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[15]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_unique_soil"
              title="Set the minimum % of unique soil in the parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Unique Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_unique_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[16]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[16]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_local_soil"
              title="Set the minimum % of local soil in the parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Local Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_local_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[17]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[17]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_non_prime_soil"
              title="Set the minimum % of non-prime soil in the parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Non-prime Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_non_prime_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[18]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[18]}}
          </span>
      </div>

      <div class="py-1 pl-10 2xl:pl-14 2xl:mt-4 text-left text-gray-500">
        <label class="pr-1 text-sm md:text-base xl:text-xl 2xl:text-2xl" 
              for="filter_irrigated_soil"
              title="Set the minimum % of irrigated capacity soil in the 
                      parcel."
              :class="{'text-cbp': zoom_threshold_met,
                      'text-gray-300': !zoom_threshold_met,
                      'cursor-not-allowed': !zoom_threshold_met,
                      'cursor-pointer': zoom_threshold_met}">
              % Irrigated Capacity Soil
        </label>
        <input type="range"
                min="0" max="100" step="1"
                class="w-1/5"
                id="filter_irrigated_soil"
                :class="{'cursor-not-allowed': !zoom_threshold_met,
                        'cursor-pointer': zoom_threshold_met}"
                :disabled="!zoom_threshold_met"
                v-model="filters[19]">
          <span class="text-sm 2xl:text-xl ml-2 inline-block"
              :class="{'text-gray-500': zoom_threshold_met,
                'text-gray-300': !zoom_threshold_met}">
            {{filters[19]}}
          </span>
      </div>
    </div>
    </form>
    <div class="pt-4 bg-white text-center">
      <span class="text-base text-cbp opacity-20">
        engage. protect. restore.
      </span>
      <img :src="blueprint_logo_footer_svg"
            alt="Conservation Blueprint footer logo" 
            class="pt-3 opacity-10 w-7/12 block mx-auto">
    </div>
  </div>

</template>

<script>

import blueprint_logo_footer_svg from 
                              "../assets/img/logos/blueprint_logo_footer.svg";

// import SelectBox from './SelectBox.vue';

export default {

  name: 'FilterComponent',

  // components: {
  //   "select-box": SelectBox
  // },

  emits: ['filters', 'filter_count', 'show_legend'],

  props: [
          'features',
          'feature_id',
          'panel_height',
          'open_space',
          'selected',
          'state_to_restore',
          'symbology',
          'zoom_threshold_met'
        ],

  data() {
    return {

      blueprint_logo_footer_svg,
      booleans: [2,8,10,11],
      color_ramp: [
          [255, 255, 0],
          [255, 232, 0],
          [255, 209, 0],
          [255, 186, 0],
          [255, 163, 0],
          [255, 140, 0],
          [255, 117, 0],
          [255, 94, 0],
          [255, 71, 0],
          [255, 48, 0],
          [255, 25, 0]
      ],
      feature_change: false,
      filters: [0,0,false,0,0,0,0,0,false,0,
                false,false,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0],

      filters_current: [0,0,false,0,0,0,0,0,false,0,
                false,false,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0],

      filters_default: [0,0,false,0,0,0,0,0,false,0,
                false,false,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0,
                0,0,0,0,0,0,0,0,0,0],

      filters_names: ['unused',
                      'Acreage',
                      'unused',
                      'Ag Model Score',
                      'Eco Model Score',
                      'Water Model Score',
                      'unused',
                      'Headwaters Score',
                      'Meets Ag Threshold',
                      '% in Ag Production',
                      'Is Adjacent to Preserved Farm',
                      'Is Infill Farm',
                      '% Within .5 Mi. of 500 Presv. Acres',
                      '% Prime Soil',
                      '% Statewide Soil',
                      '% Statewide (If Drained) Soil',
                      '% Unique Soil',
                      '% Local Soil',
                      '% Non-Prime Soil',
                      '% Irrigated Capacity Soil',
                      'Floodplain Score',
                      'Impervious Surface Score',
                      'Ground Water Recharge Score',
                      'Wetlands Score',
                      'Landscape Rank',
                      'Landscape Specied Score',
                      'Priority Site Score',
                      'Habitat Condition Score',
                      'Terrestrial Resilience Score',
                      'Coastal Resilience Score',
                      'Combined Resilience Score',
                      'Marsh Migration Space Score',
                      'Terrestrial Connectivity Score',
                      'Model CHANJ Score',
                      'CHANJ Core Score',
                      'CHANJ Corridor Score',
                      'CHANJ Stepping Stone Score',
                      'Forest Core Score',
                      'Terrestrial Core/Connectors Score'
      ],

      multipliers: [1,1,1,1,1,1,1,.1,1,1,
                1,1,1,1,1,1,1,1,1,1,
                .1,.1,.1,.1,1,1,.1,.1,.1,1,
                1,1,.1,.1,1,1,1,.1,1,1],
      show_advanced: false,
      state_restored: false
    }
  },

  computed: {

    filter_count: function () {
      return this.filters.filter(e => e).length;
    }
  },
  
  watch:{

    features: {
      immediate: true,

      handler(){
        if(!this.getFeatureLayer()){
            return false;
        }
        this.updateFeatureProps();
        this.feature_change = !this.feature_change;
      }
    },

    feature_id: {
      immediate: true,

      handler(){
        if(!this.getFeatureLayer()){
            return false;
        }
        this.updateFeatureProps();
      }
    },

    filters: {
      deep: true,
      immediate: true,

      handler(){
        if(!this.getFeatureLayer()){ return false;}

        const clean_filters = this.cleanFilters();
        this.$emit('filter_count', 
            clean_filters.filter(e => e).length);
        this.$emit('filters', {'filters': clean_filters});

        this.updateFeatureProps();
        this.feature_change = !this.feature_change;
        this.pushToAnalytics();
        this.filters_current = [...this.filters];
      }
    },

    open_space: {
      handler(){
        this.updateFeatureProps();
      }
    },

    selected: {
      immediate: true,

      handler(){
        if(!this.getFeatureLayer()){
            return false;
        }
        this.updateSelected();
      }
    },

    state_to_restore: {
      deep: true,

      handler(){
        this.restoreState();
      }
    }
  },

  mounted(){
    this.updateLegend();
  },

  methods:{
    
    adjustRangeStep($event){
      if($event.target.value < 25){
        $event.target.step = 1;
      }else if($event.target.value < 100){
        $event.target.step = 5;
      }else{
        $event.target.step = 25;
      }
    },

    cleanFilters(){
      //numeric values get retyped to string when range input changes.
      return this.filters.map(e => (typeof (e) == 'string'?+e:e));
    },

    identifySelectedFilter(){
      let i = 0;

      while(i < this.filters.length){
        if(this.filters[i] != this.filters_current[i]){
          return [this.filters_names[i], this.filters[i]];
        }
        i++;
      }
    },

    getFeatureLayer(){
      let status = false;

      if(!this.features){
        return false;
      }

      try{
        for(const layer in this.features.deck.props.layers){
          if(this.features.deck.props.layers[layer].id  == 'features'){
            status = true;
            break;
          }
        }
      }catch{
        return false;
      }
      return status;
    },

    paint(f){
      try{
        const data = f.properties.data.split('|');

        if((data[2] == '1' || data[2] == '2' || 
            data[2] == '3' || data[2] == '4'  || data[2] == '5') 
            && !this.open_space){    
            return [255, 255, 255, 0];
        }

        let ct = 0;
        
        if(!this.filter_count){
          return this.color_ramp[parseInt(0 + data[this.selected])];
        }else{
          this.filters.forEach((val, index) => {
            if(val){
              if(typeof(this.filters[index]) == 'boolean'){
                if(data[index] === 't'){
                  ct = ct + 1;
                }
              }else{
                if(parseFloat(0 +data[index]) >= val * this.multipliers[index]){
                  ct = ct + 1;
                }
              }
            }
          });

          if(ct === this.filter_count){
            let val = parseInt(0 + data[this.selected]);
            return this.color_ramp[val];
          }else{
            return [255, 255, 255, 0];
          }
        }
      }catch (error){
        return [255, 255, 255, 0];
      }
    },

    patinate(f){
      return 'solid';
    },

    pushToAnalytics(){
      const filter = this.identifySelectedFilter();

      if(filter){
        dataLayer.push({
          'event': 'filter_change',
          'filter_name': filter[0],
          'filter_value': filter[1]
        });
      }
    },

    reset(){
      this.filters = [...this.filters_default];
    },

    restoreState(){

      if(this.state_restored){
        return;
      }

      if(this.state_to_restore.filters){
            this.filters = this.state_to_restore.filters;
        }
        this.updateSelected();
        this.state_restored = true;
    },

    setBorderColor(f){
      const data = f.properties.data.split('|');
      
      if(data[0] === this.feature_id){
        return [30, 136, 229, 255];
      }

      return [255, 255, 255, 0];
    },

    setBorderWidth(f){
      const data = f.properties.data.split('|');
      
        if(data[0] === this.feature_id){
        return 6;
      }

      return 1;
    },

    updateFeatureProps(){
      if(!this.features){
        return;
      }

      this.features.setProps({
        // Removed feature patterns (getFillPattern) 11/23/23 to lessen 
        // processing needed to render layer.  Patterns were originally used to 
        // identify openspace, but are no longer needed as openspace
        // is now rendered as a seperate layer and symbolized by
        // color. - mec

        getFillColor: f => this.paint(f),
        // getFillPattern: f => this.patinate(f),
        // getFillPattern: 'solid',
        getLineColor:  f => this.setBorderColor(f), 
        getLineWidth:   f => this.setBorderWidth(f),
        updateTriggers: {
          getFillColor: [this.feature_change, this.feature_id, 
                          this.open_space, this.selected],
          // getFillPattern: [this.feature_change, this.feature_id, 
          //                 this.open_space, this.selected],
          getLineColor: [this.feature_change, this.feature_id, 
                         this.selected],
          getLineWidth: [this.feature_change, this.feature_id, 
                         this.selected],
        }
      });
    },

    updateLegend(){
 
      let mapLegend = document.getElementById('legend');
      const min = this.selected==3?4:0;
      const max = 10;
      mapLegend.innerHTML = '';

      const div_1 = document.createElement('div');
      div_1.classList.add('w-full');
      div_1.classList.add('h-6');
      div_1.classList.add('pb-0.5');
      div_1.classList.add('text-center');
      div_1.classList.add('text-base');
      div_1.classList.add('text-white');
      div_1.classList.add('bg-gradient-to-r');

      switch(parseInt(this.selected)){
        case 3:
          div_1.classList.add(`from-ag_old_0`);
          div_1.classList.add(`via-ag_old_5`);
          div_1.classList.add(`to-ag_old_10`);
          break;
        case 4:
          div_1.classList.add(`from-eco_old_0`);
          div_1.classList.add(`via-eco_old_5`);
          div_1.classList.add(`to-eco_old_10`);
          break;
        case 5:
          div_1.classList.add(`from-wa_old_0`);
          div_1.classList.add(`via-wa_old_5`);
          div_1.classList.add(`to-wa_old_10`);
          break;
      }
      div_1.innerHTML ="<span class='h-5'>Rank</span>";

      const div_2 = document.createElement('div');
      div_2.classList.add('w-full');
      div_2.classList.add('h-5');
      div_2.classList.add('bg-gray-100');
      
      const span_1 = document.createElement('span');
      span_1.classList.add('text-xs');
      span_1.classList.add('float-left');
      span_1.classList.add('pl-0.5');
      span_1.innerText = min;
      
      const span_2 = document.createElement('span');
      span_2.classList.add('text-xs');
      span_2.classList.add('float-right');
      span_2.classList.add('pr-0.5');
      span_2.innerText = max;
      div_2.appendChild(span_1);
      div_2.appendChild(span_2);

      mapLegend.appendChild(div_1);
      mapLegend.appendChild(div_2);
      this.$emit('show_legend');
    },

    updateSelected(){
      this.color_ramp = this.symbology[this.selected];
      this.updateLegend();
      this.updateFeatureProps();
    }
  }
}
</script>
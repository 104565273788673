import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { createAuth0 } from '@auth0/auth0-vue';

const app = createApp(App)
    .use(router);

app.use(
  createAuth0({
    domain: "dev-hsdi0eud.us.auth0.com",
    clientId: "IKEOaaStBvtmcfmfoC4FEx7Q6bqRqRxE",
    authorizationParams: {
      redirect_uri: 'https://conservationblueprintplus.org/#ga'
      // redirect_uri: 'https://google.com'
    }
  })
);

app.mount('#app');

<template>

  <div class="flex content-center h-auto py-4 xl:mx-0 mx-1 font-Open+Sans">
            
    <div class="inline text-gray-500 text-base md:text-lg xl:text-3xl 
                  2xl:text-3x ml-6">
            Place:
    </div>
      <div id="geocoder" 
          class="geocoder my-1 ml-2">
      </div>
   </div>

</template>

<script>
  import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
  import mapboxgl from 'mapbox-gl';
  import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

  export default{

    name: "MapboxPlaces",

    props: ['mapbox_token', 'map', 'map_loaded', 'style_change'],

    data(){
      return{
        bbox: [-75.5636, 38.9124,  -73.8851, 41.3574],
        geocoder: null,
      }
    },

    watch:{

      map: function(){  
        if(this.map){
          this.createSearch();
        }      
      },

      map_loaded: function(){
        this.addMarkerSource();
        this.addGeocoderListener();
      },

      style_change: function(){
        this.addMarkerSource();
      }  
    },

    created(){
        mapboxgl.accessToken = this.mapbox_token;
    },

    methods:{

      addMarkerInteractions(){
        this.map.on('click', 'place-marker', (e)=>{
          e.preventDefault();
          this.removeMarkerLayer();
        });
      }, 

      addMarkerLayer(){
        if(this.map.getSource('place-marker')){
            return;
        }

        this.map.addLayer({
          id: 'place-marker',
          source: 'single-point',
          type: 'circle',
          paint: {
            'circle-radius': 10,
            'circle-color': '#448ee4'
          }
        });
      },

      addMarkerSource(){
        if(this.map.getSource('single-point')){
            return;
        }
          
        this.map.addSource('single-point', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      },
    
      createSearch(){
        this.geocoder = new MapboxGeocoder({
                        accessToken: this.mapbox_token,
                        mapboxgl: mapboxgl, 
                        marker: false,
                        placeholder: 'Place name',
                        bbox: this.bbox
                      });

        document.getElementById('geocoder')
                .appendChild(this.geocoder.onAdd(this.map));

        this.geocoder.on('clear', ()=>this.removeMarkerLayer());
      },

      addGeocoderListener(){
        this.geocoder.on('result', (event) => {
          this.addMarkerLayer();
          this.map.getSource('single-point').setData(event.result.geometry);
          this.addMarkerInteractions();
          this.pushToAnalytics(event.result['place_name_en-US'])
        });
      },

      /**
       * Adds event to Google Analytics dataLayer.
       */
      pushToAnalytics(place){
        dataLayer.push({
          'event': 'place_search',
          'place_name': place
        });
      },

      removeMarkerInteractions(){
        this.map.off('click', 'place-marker', ()=>this.removeMarkerLayer());
      }, 

      removeMarkerLayer(){
        if(this.map.getLayer('place-marker')){
          this.removeMarkerInteractions();
          this.map.removeLayer('place-marker');
        }
      }
    }
  }

</script>

<style>

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 2px 2px rgba(50,50,50,.1) !important;
  border: lightgrey;
}

@media screen and (min-width: 1024) and  (max-width:1279px) {
  .mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestionst{
        width:300px;
        font-size:x-large;
        font-family: 'Open+Sans', sans-serif;
      }
}

@media screen and (min-width: 1280px) and  (max-width:1535px) {
  .mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestionst{
        width:300px;
        font-size:x-large;
        font-family: 'Open+Sans', sans-serif;
      }
}

@media screen and (min-width: 1536px){
  .mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestionst{
        width:600px;
        font-size:xx-large;
        font-family: 'Open+Sans', sans-serif;
      }
}

.mapboxgl-ctrl-geocoder--input{
  font-size:large;
}

</style>
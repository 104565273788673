import { createRouter, createWebHashHistory } from 'vue-router'
import ExplorerComponent from '../components/ExplorerComponent.vue'
import ParcelReport from '../components/ParcelReport.vue'
import HomeComponent from '../components/HomeComponent.vue'
import MultiParcelReport from '../components/MultiParcelReport.vue'
import GoogleAnalytics from '../components/GoogleAnalytics.vue'
import { authGuard } from "@auth0/auth0-vue";

const routes = [
    { path: '/', name: 'Home', component: HomeComponent, alias: '/home' },
    { path: '/Plus', name: 'Plus', component: ExplorerComponent, 
      alias: '/plus'},
    { path: '/:pathMatch(.*)*',  component: HomeComponent, alias: '/home' },
    { path: '/ParcelReport/', name:"ParcelReport", component: ParcelReport},
    { path: '/MultiParcelReport/', name:"MultiParcelReport", 
      component: MultiParcelReport },
    { path: '/Admin', name: 'Admin', component: GoogleAnalytics, 
      alias: '/admin', beforeEnter: authGuard },
    { path: '/GA', name: 'ga', component: GoogleAnalytics, 
      alias: '/ga', beforeEnter: authGuard  }
  ]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router


<template>

  <div id="map-label-toggle" 
      class="border-b border-gray-200 pb-4 hidden">                
    <span title="Show/Hide Basemap Labels"
          class="relative top-2 pt-4 pb-2"
          @click="toggleLabelLayer"
          id="control_map_labels">
      <label for="map-image-toggle" class="pl-3 pb-2">Labels
      <img class="inline-block h-5 w-5 mb-1 float-right mr-1"
            id="map-image-toggle"

            alt="Map label status"  
            :src="labels?label_hover_svg:label_off_hover_svg"
            @[labels&&`mouseover`]=
                          "swapImage($event.target,label_svg)"
            @[labels&&`mouseleave`]=
                          "swapImage($event.target,
                                        label_hover_svg)"
            @[!labels&&`mouseover`]=
                          "swapImage($event.target,label_off_svg)"
            @[!labels&&`mouseleave`]=
                          "swapImage($event.target,
                                        label_off_hover_svg)"></label>
    </span>
  </div>

</template>

<script>

  import label_svg from "../../assets/img/icons/label.svg"
  import label_hover_svg from "../../assets/img/icons/label_hover.svg"
  import label_off_svg from "../../assets/img/icons/label_off.svg"
  import label_off_hover_svg from "../../assets/img/icons/label_off_hover.svg"

  export default {

    name: "BasemapLabels",

    props:[
      'map',
      'map_loaded',
      'style_change'
    ],

    emits: ['mounted'],

    data() {
      return {
        label_svg,
        label_hover_svg,
        label_off_svg,
        label_off_hover_svg,
        labels: true,
      }
    },

    watch:{
      map_loaded: function(){  
        this.moveLabelToggle();
        this.$emit('mounted', 'BasemapLabels');
      },
      style_change: function(){ 
        this.labels = !this.labels; 
        this.toggleLabelLayer();
      }
    },

    methods:{

      hideStyleList(){
        document.getElementsByClassName('mapboxgl-style-list')[0]
        .style.display = 'none';
        document.getElementsByClassName('mapboxgl-style-switcher')[0]
        .style.display = 'block';
      },

      moveLabelToggle(){
        const el = document.getElementById('map-label-toggle');
        const target = document.getElementsByClassName('mapboxgl-style-list');

        if(target.length > 0){
          target[0].prepend(el);
          el.classList.remove('hidden');
        }
      },

      swapImage(element,src){
        element.src = src;
      },

      toggleLabelLayer(){
        this.labels = !this.labels;
        this.hideStyleList();
        const layers = this.map.getStyle().layers;

        if(this.labels){
          layers.forEach(layer=>{
            if(layer.id.includes('label') || layer.id.includes('shield')){
              this.map.setLayoutProperty(layer.id, 'visibility', 'visible');
            }
          });
        }else{
          layers.forEach(layer=>{
            if(layer.id.includes('label') || layer.id.includes('shield')){
              this.map.setLayoutProperty(layer.id, 'visibility', 'none');
            }
          });
        }
      }
    }
  }

</script>

<template>

  <div class="w-screen max-w-print z-51 mx-auto font-serif
               bg-white pb-96"
         :class="{'overflow-scroll':!multiparcel,
                  'print:overflow-visible':!multiparcel,
                  'max-h-print':!multiparcel,
                  'block':multiparcel,
                  'page-break': multiparcel}"> 

      <div class="absolute w-screen top-0 left-0 text-center noprint"
           v-if="!multiparcel">
          <button class="border-2 border-blue-700 py-1 px-4 bg-white
                  text-blue-600 rounded mr-2" 
                  aria-disabled="false" 
                  role="button" 
                  tabindex="0"
                  id="print-close"
                  @click="closeReport">
              Close
          </button>
          <button class="border-2 border-gray-100 py-1 px-5 
                        bg-blue-700 rounded-md text-white"
                  aria-disabled="false" 
                  role="button" 
                  tabindex="0"
                  id="report_print_single_report_report"
                  @click="print">
              Print
          </button>
          <csv-report
            v-if="!multiparcel"
            v-bind:multi_feature = false>
          </csv-report>
          <div class="text-center mx-auto bg-white ml-14 mt-2"
                v-if="image_source">
            <input type="checkbox"
                    id="report_report_show_image" 
                    v-model="show_image">
            <label for="report_report_show_image" 
                   class="text-xs pl-2 mr-6">include 
              image</label>
            <input type="checkbox" class="ml-6"
                    id="report_report_show_components" 
                    v-model="show_appendix">
            <label for="report_report_show_components"
                   class="text-xs pl-2">include model component data
            </label>
          </div>
      </div>

      <div class="grid grid-cols-10 w-full max-h-fit mt-20 print:mt-0">
        <div class="col-span-1 col-start-2 mt-12">
          <img class="w-full mx-auto my-auto float-right"
              alt="Conservation Blueprint logo" :src="plus_svg">
        </div>
        <div class="col-span-8 align-TOP text-left pb-6 text-3xl ml-4 mt-11">
          Conservation Blueprint<img :src="plus_black_svg" 
            class="h-3 mr-1.5 inline mb-8">
          <br>Parcel Summary
            <div class="text-left pl-2 text-sm">
                {{stamp}}
            </div>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl mb-1 mt-3 ml-2 
                    max-h-min"
              v-if="muni">
            {{ muni}}
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl mt-0  ml-2 pr-1.5
                    max-h-min"
            v-if="block">
            <span class="text-gray-600">Block:</span> {{ block}}
        </div>
 
        <div class="col-span-9 col-start-2 text-left text-xl mt-0  ml-2 pr-1.5
                    max-h-min"
            v-if="lot">
            <span class="text-gray-600">Lot:</span> {{lot}}
        </div>
 
        <div class="col-span-9 col-start-2 text-left text-xl mt-0  ml-2 pr-1.5
                    max-h-min"
            v-if="qual">
            <span class="text-gray-600">Qualifier:</span> {{qual}}
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl mt-5 ml-2 pr-1.5
                    max-h-min">
            <span class="text-gray-600">Preserved Open Space/Farmland:</span>
              {{open_space_parcels}}
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl ml-2 pr-1.5
                    max-h-min">
            <span class="text-gray-600">Area:</span>
              {{formatted_area }} acres 
            <!-- <sup class="text-black">*</sup> -->
        </div>      
        <hr class="col-span-9 col-start-1 mt-4" v-if="!show_image"> 
        
        <div class="col-span-10 content-center pb-4 mt-4"
             v-if="image_source && show_image">
          <img :src="image_source" alt="Parcel image" class="mx-auto">
        </div>
       
        <div class="col-span-9 col-start-2 text-left text-xl underline
                    underline-offset-1 max-h-min pt-5"
             :class="{'mt-0.5':show_image, 'mt-6':!show_image}">
            Priority Rankings:
        </div>

        <div class="col-span-2 col-start-2 text-right text-xl max-h-min pt-5">
            Ecological:
        </div>
        <div class="col-span-7 text-left text-xl max-h-min pt-5 pl-5">
          <span class="font-semibold">{{(Math.round((
            parseFloat(report_data.eco_score)) * 10)/ 10).toFixed(1)}}
          </span> of 10 
          <span class="font-semibold pl-2">{{relative_eco_priority}}</span>
          Priority
        </div>

        <div class="col-span-2 col-start-2 text-right text-xl max-h-min pt-1.5">
            Agricultural:
        </div>
        <div class="col-span-7 text-left text-xl max-h-min pt-1.5 pl-5">
          <span class="font-semibold pl-2">{{(Math.round((
            parseFloat(report_data.ag_score)) * 10)/ 10).toFixed(1)}}
          </span> of 10 
          <span class="font-semibold">{{relative_ag_priority}}</span> Priority
        </div>

        <div class="col-span-2 col-start-2 text-right text-xl max-h-min pt-1.5">
            Water:
        </div>
        <div class="col-span-7 text-left text-xl max-h-min pt-1.5 pl-5">
          <span class="font-semibold pl-2">{{(Math.round((
            parseFloat(report_data.wa_score)) * 10)/ 10).toFixed(1)}}
          </span> of 10  
          <span class="font-semibold">{{relative_wa_priority}}</span> Priority
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl underline
                    underline-offset-1 max-h-min pt-5 mt-20 page-break"
             :class="{'mt-0.5':show_image, 'mt-6':!show_image}">
            Parcel Characteristics:
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6 
                    ml-4">
            <span class="font-medium">Area:</span>
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl max-h-min pt-1.5 
                    pl-5 ml-4">
            {{formatted_area }} acres
              <a class="text-xs text-gray-400" 
                  xhref="https://njgin.nj.gov/njgin/edata/parcels/index.html">
                (Sourced from parcel geometry:
               https://njgin.nj.gov/njgin/edata/parcels/index.html)
              </a>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6  
                    ml-4">
          <span class="font-medium">Land Use (2015):</span>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min pt-1.5  
                    pl-5 ml-4">
          <div v-for="(item) in land_use">
            {{ item.type15.slice(0,1).toUpperCase() + item.type15.slice(1)
               .toLowerCase()}}
            {{(Math.round(((item.pct * formatted_area)/100) * 10)/10)
                .toFixed(1)}} acres ({{item.pct}}%) 
          </div>
        </div>


        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6  
                    ml-4"
              v-if="(typeof blueprint.sewers != 'undefined')" >
          <span class="font-medium">Sewer Service:</span>
          <ul class="pb-4 ml-6"
              v-if="Object.keys(blueprint.sewers).length == 0">
            <li>No Service</li>
          </ul>
          <ul v-for="item in blueprint.sewers" 
              class="pb-4 ml-6">
            <li v-for="(value, key) in item">
              {{  key.charAt(0).toUpperCase() + key.slice(1) }}: {{ value }}
            </li>
          </ul>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6  
                    ml-4"
              v-else>
          <ul class="pb-4 ml-6">
            <li>No Service</li>
          </ul>
        </div>
        <!-- <div class="col-span-9 col-start-2 text-left text-xl max-h-min pt-1.5  
                    pl-5 ml-4">
          <div v-for="(item) in report_data.response_data.sewers">
            {{ item.name }}
            {{(Math.round(((item.pct * formatted_area)/100) * 10)/10)
                .toFixed(1)}} acres ({{item.pct}}%) 
          </div>
        </div> -->
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6 
                    ml-4">
          <span class="font-medium">Farm Soils:</span>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min pt-1.5 
                    pl-5 ml-4">
            <div v-show="farm_soils.prime[0] != 0">
              Prime: {{farm_soils.prime[1]}} acres ({{farm_soils.prime[0]}}%)
            </div>
            <div v-show="farm_soils.statewide[0] != 0">
              Statewide: {{farm_soils.statewide[1]}} acres 
                        ({{farm_soils.statewide[0]}}%)
            </div>
            <div v-show="farm_soils.statewide_drained[0] != 0">
              Statewide (if drained): {{farm_soils.statewide_drained[1]}} acres 
                        ({{farm_soils.statewide_drained[0]}}%)
            </div>
            <div v-show="farm_soils.unique[0] != 0">
              Unique: {{farm_soils.unique[1]}} acres 
                        ({{farm_soils.unique[0]}}%)
            </div>
            <div v-show="farm_soils.local[0] != 0">
              Local: {{farm_soils.local[1]}} acres 
                        ({{farm_soils.local[0]}}%)
            </div>
            <div v-show="farm_soils.other[0] != 0">
              Other: {{farm_soils.other[1]}} acres 
                        ({{farm_soils.other[0]}}%)
            </div>
            <div class="mt-3" v-show="farm_soils.irrigated_capacity[0] != 0">
              Irrigated Capacity Soils: {{farm_soils.irrigated_capacity[1]}}
              acres ({{farm_soils.irrigated_capacity[0]}}%)
            </div>
        </div>

        <div class="col-span-9 col-start-2 text-left text-xl max-h-min mt-6 
                    ml-4">
          <span class="font-medium">Watersheds:
          </span>
        </div>
        <div class="col-span-9 col-start-2 text-left text-xl max-h-min pt-1.5 
                    pl-5 ml-4">
          <div v-for="(item) in blueprint.watersheds">
            {{ item.name.slice(0,1).toUpperCase() + item.name.slice(1)
               .toLowerCase()}}
            {{(Math.round(((item.pct * formatted_area)/100) * 10)/10)
                .toFixed(1)}} acres ({{item.pct}}%) 
          </div>
        </div>

        <div id="modiv-report" 
                class="col-span-10 mt-20 mb-16 grid grid-cols-10 page-break">
            <div class="col-span-10 text-xl underline underline-offset-1 py-4 
                        ml-8"
                  v-show="Object.keys(modiv).length != 0">
                Tax Information
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('redirect')">
                Not available.
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('address')">
                Address:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('address')">
                {{ modiv.address }}
            </div>

            <div class="col-span-7 col-start-4 text-left text-lg ml-6
                        -mt-1 max-h-min"
                v-show="modiv.hasOwnProperty('town')">
                {{ modiv.town }}
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('owner')">
                Owner:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('owner')">
                {{ modiv.owner}}
            </div>

            <div class="col-span-7 col-start-4 text-left text-lg ml-6
                        -mt-1 max-h-min"
                v-show="modiv.hasOwnProperty('owner_address_1')">
                {{ modiv.owner_address_1 }}
            </div>
            <div class="col-span-7 col-start-4 text-left text-lg ml-6
                        -mt-1 max-h-min"
                v-show="modiv.hasOwnProperty('owner_address_2')">
                {{ modiv.owner_address_2 }}
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('zoning')">
                Zoning:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('zoning')">
                {{ modiv.zoning?modiv.zoning:'N/A' }}
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('assessment')">
                Assessment:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('assessment')">
                {{ modiv.assessment }}
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('taxes')">
                Taxes:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('taxes')">
                {{ modiv.taxes }}
            </div>
            <div class="col-span-3 text-right text-lg text-gray-500
                        mt-0 max-h-min"
                v-show="modiv.hasOwnProperty('property_class')">
                Class:
            </div>
            <div class="col-span-7 text-left text-lg ml-6 max-h-min"
                v-show="modiv.hasOwnProperty('property_class')">
                ({{ modiv.property_class }})
                <span v-show="modiv.hasOwnProperty('description')">
                    {{ modiv.description }}
                </span>
            </div>
        </div>

        <div id="disclaimer" 
             class="col-span-10 grid grid-cols-10 page-break"
             v-if="!multiparcel">
            <div class="col-span-10 text-xl underline underline-offset-1 py-4 
                        ml-8">
                Disclaimer
            </div>
        </div>
        <div class="col-span-8 col-start-2 text-base pb-36"
             v-if="!multiparcel">
          <p class="">The Conservation Blueprint utilizes best available 
          data from a
          variety of sources. These sources can be made available so users
          may assess the integrity and objectivity of the data. While
          attention is given to present the most up-to-date information,
          The Conservation Blueprint Project Team, and its funders, assume
          no responsibility for the spatial accuracy, completeness or
          timeliness of data used, and expressly disclaim any and all
          responsibility for errors, misinterpretations, omissions or other
          inconsistencies depicted arising from or otherwise related to the
          maps maintained within this site.</p>

          <p class="mt-3">Maps and data sets found on this site are for planning 
            activities
          only and cannot and should not be used for any regulatory
          purposes - this applies to both the parcel and state-wide levels.
          The information on this website should be used only as a guide;
          an on-site investigation is the only true way to know which
          features exist on the ground.</p>

          <p class="mt-3">Priority Land rankings for each theme were determined 
          by The
          Conservation Blueprint Project Team in conjunction with the
          Conservation Blueprint Steering Committee and corresponding
          Advisory Committees. Priority Lands and Rankings reflect the
          collaborative input, deliberation and agreed upon determinations
          of the aforementioned groups. Users acknowledge that Blueprint
          rankings are not linked to any other state ranking system, and
          are not directly linked to any funding sources. Blueprint
          rankings do not replace a municipality or county’s own
          prioritization.</p>

          <p class="mt-3">Full methodology reports will be available on the 
          Conservation
          Blueprint website. Revisions to methodology will occur
          periodically based upon the inclusion of updated or new data
          sets, as well as feedback from the Conservation Blueprint
          Steering and Advisory Committees.</p>

          <p class="mt-3">The Conservation Blueprint Project Team would 
          appreciate feedback
          on any errors that are discovered when using this site by
          contacting us at conservationblueprint@gmail.com.</p>
        </div>
      </div>

      <div id="appendix_a" 
           class="col-span-10 grid grid-cols-10 mt-20 page-break"
            v-if="show_appendix">
          <div class=" col-span-10 text-2xl underline  underline-offset-1 py-4
                      ml-8">
            Appendix A.  Model details and model component scores
          </div>
          <div class="col-span-8 col-start-2 my-4 ml-2 font-bold text-xl">
              Ecological Model
          </div>
          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <p>Data components for the ecological model are grouped into four 
                categories:
              </p>
              <ul class="list-disc ml-8 my-2">
                  <li>Water</li>
                  <li>Rare Species and Natural Communities</li>
                  <li>Climate Change Resilience</li>
                  <li>Habitat Connectivity</li>
              </ul> 
              <p>Each component is scored on a different scale. The score for a 
                category is the sum of the component scores, normalized to 10.
              </p>
              <p class="mt-1.5">To calculate the model score, category scores 
                are added together. This total is also normalized to 10 to align
                with the other priority models. 
              </p>
              <p class="mt-1.5">Additonal information about the Ecological model
                can be found in the model methodology document:<br>
                <a href=
"https://conservationblueprint-nj.com/docs/Ecosystems_to_Protect_Model_2020.pdf"
                   target="_blank"
                   class="text-blue-500">
 https://conservationblueprint-nj.com/docs/Ecosystems_to_Protect_Model_2020.pdf
                </a> 
              </p>
          </div>

          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-5/6">
                <thead>
                  <tr>
                    <th colspan="3" class="text-lg font-semibold text-left">
                      Normalized Water Score: {{eco.normalized_water_score}}
                    </th>
                  </tr>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Headwaters</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.headwaters_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Floodplains</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.floodplain_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Impervious Surface</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.impervious_surface_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Groundwater Recharge</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.groundwater_recharge_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Wetlands</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.wetlands_score}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-5/6">
                <thead>
                  <tr>
                    <th colspan="3" class="text-lg font-semibold text-left 
                                           whitespace-nowrap">
                      Normalized Rare Species and Natural Communities Score: 
                      {{eco.normalized_rsnc_score}}
                    </th>
                  </tr>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Landscape Version 3.3: Rank</td>
                    <td class="pl-4">0 - 5</td>
                    <td class="pl-4">{{eco.landscape_rank_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Landscape Version 3.3 # of unique<br>species/patch</td>
                    <td class="pl-4">0 - 5</td>
                    <td class="pl-4">{{eco.species_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Vernal Habitat (Landscape V3.3)</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.vernal_habitat_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Natural Heritage Priority Sites</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.priority_sites_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Nature's Network Habitat Condition</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.habitat_conditions_score}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-5/6">
                <thead>
                  <tr>
                    <th colspan="3" class="text-lg font-semibold text-left">
                      Normalized Climate Change Resilience Score: 
                      {{eco.normalized_resilience_score}}
                    </th>
                  </tr>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Resilience (coastal & terrestrial combined)</td>
                    <td class="pl-4">0 - 5</td>
                    <td class="pl-4">{{eco.combined_resilience_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Marsh Migration Space</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.marsh_migration_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Terrestrial Connectivity</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.terrestrial_resilience_score}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-5/6">
                <thead>
                  <tr>
                    <th colspan="3" class="text-lg font-semibold text-left">
                      Normalized Habitat Connectivity Score:
                      {{eco.normalized_connectivity_score}}
                    </th>
                  </tr>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">CHANJ (Connecting Habitat Across New Jersey)</td>
                    <td class="pl-4">0 - 10</td>
                    <td class="pl-4">{{eco.final_chanj_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Forest Core</td>
                    <td class="pl-4">0 - 3</td>
                    <td class="pl-4"
                    >{{ Math.round(( eco.forest_core_score + Number.EPSILON) * 100) / 100}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Nature's Network Habitat Connectivity</td>
                    <td class="pl-4">0 - 5</td>
                    <td class="pl-4">{{eco.terrestrial_core_connectors_score}}</td>
                  </tr>
                </tbody>
              </table>
          </div>

          <div class="col-span-8 col-start-2 mt-20 mb-4 ml-2 font-bold text-xl
                      page-break">
              Agricultural Model
          </div>
          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <p>Identifies lands not currently preserved with characteristics 
                most conducive for agricultural viability indicated by current 
                agricultural land use, quality of soils, and proximity to 
                already preserved farmland.
              </p>
              <p>Priority points (up to a maximum of 6) were assigned based on 
                the portion of the parcel where the following are present:
              </p>
              <ul class="list-disc ml-8 my-2">
                  <li>Land in agricultural use
                       (Derived from the NJDEP 2015 Land Use/Land Cover.)</li>
                  <li>Prime farm soils</li>
                  <li>Statewide important farm soils</li>
                  <li>Unique or locally important farm soils</li>
              </ul> 
              <p class="mt-1.5">Additional points (up to a maximum of 3) were 
                assigned based on the location of the parcel relative to already
                preserved farmland.  
              </p>
              <ul class="list-disc ml-8 my-2">
                  <li>Parcels that are adjacent to aleady preserved farmland
                  </li>
                  <li>Parcels within ½ mile of already preserved farmland</li>
                  <li>Infill area.  The percentage of the parcel that is within 
                    one mile of 500 acres of already preserved farmland
                  </li>
              </ul> 
              <p class="mt-1.5">An additional point was given to <em>
                AgriculturalThreshold Parcels</em>. These are parcels of 5
                acres or more with at least 50% of the land in agricultural use,
                or parcels of 50 acres or more with at least 25 acres in 
                agricultural use.
              </p>
              <p class="mt-1.5">Additional information about the Agricultural
                model can be found in the model methodology document:<br>
                <a href="
https://conservationblueprint-nj.com/docs/Agricultural_Priority_Model2020.pdf"
                   target="_blank"
                   class="text-blue-500">
https://conservationblueprint-nj.com/docs/Agricultural_Priority_Model2020.pdf
                </a> 
              </p>

              <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-11/12">
                <thead>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel in agricultural use</td>
                    <td class="pl-4">0 - 3</td>
                    <td class="pl-4">{{(Math.round((3 * 
                    parseFloat(ag.percent_in_agricultural_production/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel with prime soil</td>
                    <td class="pl-4">0 - 3</td>
                    <td class="pl-4">{{(Math.round((3 * 
                    parseFloat(ag.percent_prime_soil/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel with soil of statewide importance
                    </td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{(Math.round((2 * 
                    parseFloat(ag.percent_statewide_soil/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel with soil of statewide importance
                    (if drained)</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{(Math.round((2 * 
                    parseFloat(ag.percent_statewide_if_drained_soil/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel with soil of unique importance</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{(Math.round((
                      parseFloat(ag.percent_unique_soil/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Percentage of parcel with soil of local importance</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{(Math.round((
                    parseFloat(ag.percent_local_soil/100)) * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Parcel is adjacent to aleady preserved farmland</td>
                    <td class="pl-4">0 or 1</td>
                <td class="pl-4">{{ag.adjacent_to_preserved_farm == 'Yes'?1:0}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Percentage of parcel within ½ mile of already preserved
                      farmland </td>
                    <td class="pl-4">0 or 1</td>
                    <td class="pl-4">{{(Math.round((parseFloat(
                      ag.percent_within_half_mile_of_500_preserved_acres/100))
                      * 10)
                    / 10).toFixed(1)}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Infill parcel</td>
                    <td class="pl-4">0 or 1</td>
                    <td class="pl-4">{{ag.infill_farm == 'Yes'?1:0}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Parcel meets the agricultural threshold</td>
                    <td class="pl-4">0 or 1</td>
                    <td class="pl-4">{{ag.meets_agricultural_threshold == 'Yes'?1:0}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
          <div class="col-span-8 col-start-2 mt-20 mb-4 ml-2 font-bold text-xl
                      page-break">
              Water Model
          </div>
          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <p>This model is one of four components used in the Ecological 
                model. It ranks parcels that are important for water and water 
                resources. Parcels are evaluated based on the presence of:</p>
              <ul class="list-disc ml-8">
                <li>Areas in natural cover (where water can recharge aquifers)</li>
                <li>Wetlands</li>
                <li>Floodplains</li>
                <li>Headwater streams</li>
              </ul>
              <p>This model is one of four components used in the Ecological 
                model.</p>
              <p class="mt-1.5">Additional information about the Water model
                can be found in the Ecological model methodology document:<br>
                <a href=
"https://conservationblueprint-nj.com/docs/Ecosystems_to_Protect_Model_2020.pdf"
                   target="_blank"
                   class="text-blue-500">
https://conservationblueprint-nj.com/docs/Ecosystems_to_Protect_Model_2020.pdf
                </a> 
              </p>
          </div>

          <div class="col-span-9 col-start-2 mb-4 ml-5">
              <table class="w-5/6">
                <thead>
                  <tr class="text-left">
                    <th class="underline font-normal">Component</th>
                    <th class="underline font-normal">Score Range</th>
                    <th class="underline font-normal">Parcel Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Headwaters</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.headwaters_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Floodplains</td>
                    <td class="pl-4">0 - 2</td>
                    <td class="pl-4">{{eco.floodplain_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Impervious surface</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.impervious_surface_score}}</td>
                  </tr>
                  <tr>
                    <td class="pl-4">Groundwater recharge</td>
                    <td class="pl-4">0.0 to 1.0</td>
                    <td class="pl-4">{{eco.groundwater_recharge_score}}</td>
                  </tr>
                  <tr class="bg-slate-50">
                    <td class="pl-4">Wetlands</td>
                    <td class="pl-4">0 - 1</td>
                    <td class="pl-4">{{eco.wetlands_score}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
  </div>

</template>

<script>

    import axios from 'axios';
    import ParcelReportCSV from "./ParcelReportCSV.vue";
    import plus_black_svg from "../assets/img/logos/plus_black_no_text.svg";
    import plus_svg from "../assets/img/logos/plus.svg";
    import ModivComponent from "./ModivComponent.vue";
    export default {

        name: 'ParcelReport',

        components: {
            "csv-report": ParcelReportCSV,
            "modiv-report":  ModivComponent
        },

        emits: [
          'has_image'
        ],

        props:{
          include_appendices:{
            type:Boolean,
            default: false
          },

          include_image:{
            type:Boolean,
            default: false
          },

          item_data: {
            type: String,
            default: ''
          },
        },

        watch:{
          include_appendices: function(){
            this.show_appendix = this.include_appendices;
          },
          include_image: function(){
            this.show_image = this.include_image;
          }
        },

        data() {
            return {
                acreage: 0,
                ag: {},
                ag_components_1: {},
                ag_components_2: {},
                block: '',
                blueprint: {},
                eco: {},
                eco_components_1: {},
                eco_components_2: {},
                image_source: null,
                land_use: null,
                lot: '',
                modiv: {},
                multiparcel: false,
                muni: '',
                open_space_parcels: null,
                plus_black_svg,
                plus_svg,
                qual: '',
                report_data: {},
                show_appendix: false,
                show_image: true,
                stamp: new Date().toLocaleDateString('en-us', 
                {weekday:"long", year:"numeric", month:"short", day:"numeric"}),
                wa: {},
                wa_components_1: {},
                wa_components_2: {}
            }
        },

        computed: {

          /**
           * Computed variable.  Returns area formatted to one decmal place.
           * @returns {float}
           */
           formatted_area(){
            return Math.round(parseFloat(this.report_data.acreage) * 10) / 10;
          },

          /**
           * Computed variable.  Returns relative agricultural model ranking.
           * @returns {text}
           */
          relative_ag_priority: function(){

            if(this.report_data.ag_score >= 4 && 
               this.report_data.ag_score <= 5){
              return 'LOW';
            }else  if(this.report_data.ag_score > 5 && 
                      this.report_data.ag_score <= 7){
              return 'MEDIUM';
            }else  if(this.report_data.ag_score > 7){
              return 'HIGH';
            }else{
              return 'NO';
            }
          },

          /**
           * Computed variable.  Returns relative ecological model ranking.
           * @returns {text}
           */
          relative_eco_priority: function(){

            if(this.report_data.eco_score <= 2 ){
              return 'LOW';
            }else  if(this.report_data.eco_score <= 4 ){
              return 'MEDIUM-LOW';
            }else  if(this.report_data.eco_score <= 6 ){
              return 'MEDIUM';
            }else  if(this.report_data.eco_score <= 8 ){
              return 'MEDIUM-HIGH';
            }else  if(this.report_data.eco_score <= 10 ){
              return 'HIGH';
            }else{
              return '';
            }
          },

          /**
           * Computed variable.  Returns relative water model ranking.
           * @returns {text}
           */
          relative_wa_priority: function(){

            if(this.report_data.wa_score <= 2 ){
              return 'LOW';
            }else  if(this.report_data.wa_score <= 4 ){
              return 'MEDIUM-LOW';
            }else  if(this.report_data.wa_score <= 6 ){
              return 'MEDIUM';
            }else  if(this.report_data.wa_score <= 8 ){
              return 'MEDIUM-HIGH';
            }else  if(this.report_data.wa_score <= 10 ){
              return 'HIGH';
            }else{
              return '';
            }
          },

          /**
           * Computed variable.  Returns farm soils as json.
           * @returns {float}
           */
          farm_soils(){
            let soils = {};
             soils['prime'] = [
                this.ag.percent_prime_soil, 
                (this.ag.percent_prime_soil/100 * this.acreage).toFixed(1)];
             soils['statewide'] = [
                this.ag.percent_statewide_soil, 
                (this.ag.percent_statewide_soil/100 * this.acreage).toFixed(1)];
             soils['statewide_drained'] = 
                [this.ag.percent_statewide_if_drained_soil, 
                (this.ag.percent_statewide_if_drained_soil/100 * 
                this.acreage).toFixed(1)];
             soils['unique'] = 
                [this.ag.percent_unique_soil, 
                (this.ag.percent_unique_soil/100 * this.acreage).toFixed(1)];
             soils['local'] = 
                [this.ag.percent_local_soil, 
                (this.ag.percent_local_soil/100 * this.acreage).toFixed(1)];
             soils['irrigated_capacity'] = 
                [this.ag.percent_irrigated_capacity_soil, 
                (this.ag.percent_irrigated_capacity_soil/100 * 
                this.acreage).toFixed(1)];
             soils['other'] = 
                [this.ag.percent_not_prime_soil, 
                (this.ag.percent_not_prime_soil/100 * this.acreage).toFixed(1)];
             
            return soils;
          }
        },

        methods: {

            cleanString(str){
                let arr = str.split('_').map(element => {
                    return element[0].toUpperCase() + element.substring(1);
                });

                let out_str = arr.join(' ').replace('Score', '');
                
                return out_str;

            },

            closeReport(){
                window.close();
            },

            filterData(data){
                const keys = Object.keys(data);

                for(const key in keys){
                    const lc_key = keys[key].toLowerCase();

                    if(lc_key == 'score' || 
                       lc_key == 'total_parcel_acreage' || 
                       lc_key.includes('raw') ||
                       lc_key.includes('normalized') || 
                       lc_key.includes('message'))
                    {
                        delete data[keys[key]];
                    }
                }
                return data;
            },

            getImageSource(){
              let url = 'https://gspr34zib0.execute-api.us-east-1.amazonaws.com'
                      + '/default/get-static-map-image?pams_pin='
                      + this.blueprint.feature_id;

                  return new Promise((resolve, reject) => {
                      axios.get(url)
                          .then(response => {
                              this.image_source = response.data;
                              this.$emit('has_image', true);
                          })
                           .catch(error => {
                            this.image_source = null;  
                          });
                  });
            },

            print(){
                window.print();
            },

            processData(){

                if(this.report_data.hasOwnProperty('muni')){
                    this.muni = this.report_data.muni;
                }

                if(this.report_data.hasOwnProperty('block')){
                    this.block = this.report_data.block;
                }

                if(this.report_data.hasOwnProperty('lot')){
                    this.lot = this.report_data.lot;
                }

                if(this.report_data.hasOwnProperty('qual')){
                    this.qual = this.report_data.qual;
                }

                if(this.report_data.hasOwnProperty('acreage')){
                    this.acreage = this.report_data.acreage;
                }
                
                if(this.report_data.hasOwnProperty('response_data')){
                    this.blueprint = 
                        Object.assign({}, this.report_data.response_data);

                    this.land_use = 
                        Object.assign({}, 
                                      this.report_data.response_data.land_use);
                            
                    if(this.blueprint.hasOwnProperty('os')){
                      switch (this.blueprint.os) {
                        case 0:
                          this.open_space_parcels = 'No'
                          break;
                        case 1:
                          this.open_space_parcels = 'Yes - Open Space'
                          break;
                        case 2:
                          this.open_space_parcels = 'Yes - Farmland'
                          break;
                        case 3:
                          this.open_space_parcels = 'Yes - (Military)'
                          break;
                        case 4:
                          this.open_space_parcels = 'Yes - (Military)'
                          break;
                        case 5:
                          this.open_space_parcels = 'No - (Military)'
                           break;
                        default:
                          this.open_space_parcels = 'No'
                          break;
                      }
                    }
                    
                    if(this.blueprint.hasOwnProperty('eco')){
                        this.eco = Object.assign({}, this.blueprint.eco);
                        if(this.eco.hasOwnProperty('total_parcel_acreage')){
                            this.acres = this.eco.total_parcel_acreage;
                        }
                        
                        let components = 
                                this.filterData(Object.assign({}, this.eco));
                        let keys = Object.keys(components)
                        let len = keys.length;
                        let stop = Math.floor(len/2);

                        for(let i=0; i<=stop; i++){
                            this.eco_components_1[keys[i]] = 
                                                            components[keys[i]];
                        }
                        for(let i=stop+1; i<len; i++){
                            this.eco_components_2[keys[i]] = 
                                                            components[keys[i]];
                        }
                    }
                    
                    if(this.blueprint.hasOwnProperty('ag')){
                        this.ag = Object.assign({}, this.blueprint.ag);
                        if(this.ag.hasOwnProperty('total_parcel_acreage')){
                            this.acres = 
                            this.acres?this.acres:this.ag.total_parcel_acreage;
                        }
                        
                        let components = 
                                this.filterData(Object.assign({}, this.ag));
                        let keys = Object.keys(components)
                        let len = keys.length;
                        let stop = Math.floor(len/2);

                        for(let i=0; i<=stop; i++){
                            this.ag_components_1[keys[i]] = 
                                                            components[keys[i]];
                        }
                        for(let i=stop+1; i<len; i++){
                            this.ag_components_2[keys[i]] = 
                                                            components[keys[i]];
                        }
                    }
                    
                    if(this.blueprint.hasOwnProperty('wa')){
                        this.wa = Object.assign({}, this.blueprint.wa);
                        if(this.wa.hasOwnProperty('total_parcel_acreage')){
                            this.acres = 
                            this.acres?this.acres:this.wa.total_parcel_acreage;
                        }
                        
                        let components = 
                                this.filterData(Object.assign({}, this.wa));
                        let keys = Object.keys(components)
                        let len = keys.length;
                        let stop = Math.floor(len/2);

                        for(let i=0; i<=stop; i++){
                            this.wa_components_1[keys[i]] = 
                                                            components[keys[i]];
                        }
                        for(let i=stop+1; i<len; i++){
                            this.wa_components_2[keys[i]] = 
                                                            components[keys[i]];
                        }
                    }
                }

                if(this.report_data.hasOwnProperty('modiv')){
                    if(Object.keys(this.report_data.modiv).length === 0){
                      this.modiv = {};
                    }else{
                      let data =  Object.assign({}, this.report_data.modiv);
                      this.modiv = this.filterData(data);
                    }
                }
            }
        },

        mounted(){
          if(this.item_data){
            this.report_data = this.item_data;
            this.multiparcel = true;
          }else{
            this.report_data = JSON.parse(this.$route.query.data);
          }

          this.processData(this.report_data);
          this.getImageSource();
        }
    }

</script>

<style scoped>
    @media print {
      .noprint {visibility: hidden;}
      .page-break{ page-break-inside:avoid; page-break-before: always; }
    }
</style>
<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-ref_comp_bg_closed': !showing,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.fill.source.minzoom,
                'text-blue-700': showing && zoom >= 
                                                    overlay.fill.source.minzoom,
                'text-blue-400': !showing && zoom >= 
                                                  overlay.fill.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        title="Displays the Watersheds Overlay"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-xl 
               2xl:text-2xl"
        :class="{'text-ref_comp_bg': showing,
                'text-ref_text': !showing,
                'cursor-pointer': hover_title && zoom >= 
                                                    overlay.fill.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= 
                                      overlay.fill.source.minzoom || showing)}"
        @click="toggleLayer">Watersheds Huc14
      </div>
      <div class="text-xs xl:text-sm 2xl:text-lg -mt-0.5 ml-1 
                  col-span-9 col-start-2 text-ref_text_min"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" class="hover:underline">(NJDEP, 2016)</a>
        <span v-else>(NJDEP, 2016)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        <input type="range"
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)"
              v-model="opacity">
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-2xl text-gray-700"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_watersheds">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_watersheds">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>
      <div class="col-span-1 col-start-2 border-huc14_borders border-2
                 bg-huc14"
           :class="opacity_class"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.fill.source.minzoom">
        Watersheds
      </div>
    </div>
  </div>

</template>

<script>

  import axios from 'axios';

  export default {

      name: "HUC14Watersheds",

      data(){
          return{
              data_error: '',
              data_name: 'huc14_watersheds',
              hover_meta: false,
              hover_source: false,
              hover_title: false,
              label: 'HUC 14 Watersheds',
              opacity: 50,
              overlay: {
                fill:{
                  'source': {
                    'name': 'huc14-watersheds',
                    'type': 'vector',
                    'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                              + 'huc14-watersheds/{z}/{x}/{y}.pbf'],
                    'minzoom': 6,
                    'maxzoom': 15,
                  },
                  'layer': {
                      'id': 'huc14-watersheds',
                      'type': 'fill',
                      'source': 'huc14-watersheds',
                      'source_layer': 'water.huc14_watersheds',
                      'paint':  {
                        'fill-color': '#0000a3',
                        'fill-outline-color': '#0096FF',
                        'fill-opacity': .2
                      }
                  }
                },
                border:{
                  'source': {
                    'name': 'huc14-borders',
                    'type': 'vector',
                    'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                              + 'huc14-borders/{z}/{x}/{y}.pbf'],
                    'minzoom': 6,
                    'maxzoom': 15,
                  },
                  'layer': {
                    'id': 'huc14-borders',
                    'type': 'line',
                    'source': 'huc14-borders',
                    'source_layer': 'water.huc14_borders',
                    'paint':  {
                      'line-width': 3,
                      'line-color': '#8a8aff',
                      'line-opacity': 0.75,
                      'line-dasharray':["literal", [1, 2]]
                    }
                  }
                }
              },
              showing: false,
              source_data: {'metadata_url': '',
                            'source_url': '',
                            'description': ''},
          }
      },

    props:['show_features', 'label_layer_id', 'layers_to_load', 
            'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

    emits: ['add', 'mounted', 'remove', 'popup'],
  
    computed: {

      /**
       * Computed variable.  Returns tailwind bg-opacity class that matches 
       * the overlay's fill opacity
       * @returns {string}
       */
      opacity_class: function(){
        let cls = '';

        switch(true){
          case (this.opacity < 10):
            cls = 'bg-opacity-0';
            break;
          case (this.opacity < 20):
            cls = 'bg-opacity-10';
            break;
          case (this.opacity < 30):
            cls = 'bg-opacity-20';
            break;
          case (this.opacity < 40):
            cls = 'bg-opacity-30';
            break;
          case (this.opacity < 50):
            cls = 'bg-opacity-40';
            break;
          case (this.opacity < 60):
            cls = 'bg-opacity-50';
            break;
          case (this.opacity < 70):
            cls = 'bg-opacity-60';
            break;
          case (this.opacity < 80):
            cls = 'bg-opacity-70';
            break;
          case (this.opacity < 90):
            cls = 'bg-opacity-80';
            break;
          case (this.opacity < 100):
            cls = 'bg-opacity-90';
            break;
          default:
            cls = 'bg-opacity-100';
        }
        return cls;
      }
    },

    created(){
      this.overlay.fill.layer.paint['fill-opacity'] = this.opacity/100;
    },

    mounted(){
      this.getLayerInfo();
      this.$emit('mounted', this.overlay.fill.layer.id);
    },

    watch:{

      map_loaded: function(){
        this.addSource();
        if(this.layers_to_load.includes(this.overlay.fill.layer.id)){
          this.addLayer();
          this.showing = true;
        }
      },

      reset: function(){
        this.reset_layer();
      },

      style_change: function(){
        this.addSource();
        if(this.showing){
          this.addLayer();
        }
      }
    },

    methods:{

      addInteractions(){
        this.removeInteractions();

        this.map.on('click', 
                          this.overlay.fill.layer.id, this.handleLayerClick);

        this.map.on('mouseenter',  this.overlay.fill.layer.id, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        
        this.map.on('mouseleave',  this.overlay.fill.layer.id, () => {
          this.map.getCanvas().style.cursor = '';
        });


      },

      addLayer(){
        if(this.map.getLayer(this.overlay.fill.layer.id)){
          return;
        }
      
        this.map.addLayer({
          'id': this.overlay.fill.layer.id,
          'type': this.overlay.fill.layer.type,
          'source': this.overlay.fill.layer.source, 
          'source-layer': this.overlay.fill.layer.source_layer,
          'paint': this.overlay.fill.layer.paint
        }, this.label_layer_id);

        this.map.addLayer({
          'id': this.overlay.border.layer.id,
          'type': this.overlay.border.layer.type,
          'source': this.overlay.border.layer.source, 
          'source-layer': this.overlay.border.layer.source_layer,
          'paint': this.overlay.border.layer.paint
        }, this.label_layer_id);

        if(this.map.getLayer('feature-borders')){
          this.map.moveLayer('feature-borders',this.label_layer_id);
        }

        this.addInteractions();
      },

      addSource(){
          if(!this.map.getSource(this.overlay.fill.source.name)){
            this.map.addSource(this.overlay.fill.source.name, {
                'type': this.overlay.fill.source.type,
                'tiles': this.overlay.fill.source.tiles,
                'minzoom': this.overlay.fill.source.minzoom,
                'maxzoom': this.overlay.fill.source.maxzoom,
            });
          }

          if(!this.map.getSource(this.overlay.border.source.name)){
            this.map.addSource(this.overlay.border.source.name, {
                'type': this.overlay.border.source.type,
                'tiles': this.overlay.border.source.tiles,
                'minzoom': this.overlay.border.source.minzoom,
                'maxzoom': this.overlay.border.source.maxzoom,
            });
          }
      },

      getLayerInfo(){
          let url = 'https://gspr34zib0.execute-api.us-east-1.';
              url += 'amazonaws.com/default/data-retriever?action=';
              url += `get_data_source_summary&data_name=${this.data_name}`;

          axios.get(url)
              .then(response => {
                  if(response.hasOwnProperty('data')){
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                  }else{
                        this.data_error = 'Failed to get data from server.';  
                  }
              })
                .catch(error => {
                  this.data_error  = 'Data request error.' 
                                  + ' Server said: ' + error?error:'Nothing';  
              });
      },

      handleLayerClick(e){
      
        const html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
                    Watershed (${e.features[0].properties.huc14})</p>
                    <hr class="mb-1.5">
                      <p class="text-left">
                      ${e.features[0].properties.w_name}</p`;

        this.$emit('popup', {'id': this.overlay.fill.layer.id, 'html': html,
                  'coords': e.lngLat});
      },

      removeLayer(){
        this.removeInteractions();
        this.map.removeLayer(this.overlay.fill.layer.id);
        this.map.removeLayer(this.overlay.border.layer.id);
      },

      removeInteractions(){
        this.map.off('click', 
                          this.overlay.fill.layer.id, this.handleLayerClick);
        
        this.map.off('mouseenter',  this.overlay.fill.layer.id, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });
        
        this.map.off('mouseleave',  this.overlay.fill.layer.id, () => {
          this.map.getCanvas().style.cursor = '';
        });
      },

      reset_layer(){
        if(this.showing){
          this.removeLayer();
          this.$emit('remove', this.overlay.fill.layer.id);
          this.showing = false;
        }
      },

      setOpacity(){
        this.map.setPaintProperty(this.overlay.fill.layer.id, 
                                            'fill-opacity', this.opacity/100);
        let line_opacity = this.opacity > 50? 1: this.opacity*2/100;     
        line_opacity = line_opacity < .2?.2:line_opacity;
        
        this.map.setPaintProperty(this.overlay.border.layer.id, 
                      'line-opacity', line_opacity);
      },

      toggleLayer(){
        if(this.showing){
          this.removeLayer();
          this.$emit('remove', this.overlay.fill.layer.id);
        }else{
          this.addLayer();
          this.$emit('add', this.overlay.fill.layer.id);
        }
        this.showing = !this.showing;
      }
    }
  }

</script>

<template>
 
      <div class="pr-0.5 md:pr-5" 
           @click="toggleBlueprintLayer"
           id="setting_blueprint_features">
        <span class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-16 2xl:w-16
                     rounded-full hover:bg-blue-400"
              title="Turn Blueprint Overlay On and Off"
              :class="{ 'bg-blue-100': showing,
                        'bg-red-100': !showing}">
            <img class="w-4 md:w-7 lg:w-6 2xl:w-10 mx-auto my-auto"
              :src="show_features?
                      toggle_on_hover_svg:toggle_off_hover_svg"
                 alt="Shows/hides Conservagion Blueprint madel layer"  
              @[show_features&&`mouseover`]=
                    "swapImage($event.target, toggle_on_svg)"
              @[show_features&&`mouseleave`]=
              "swapImage($event.target, toggle_on_hover_svg)"
              @[!show_features&&`mouseover`]=
                      "swapImage($event.target, toggle_off_svg)"
              @[!show_features&&`mouseleave`]=
                "swapImage($event.target, toggle_off_hover_svg)">
        </span>
        <div class="text-xs md:text-sm lg:text-base 2xl:text-2xl text-blue-500 
                  text-extrabold block -ml-1 h-4 md:h-6"
              :class="{ 'line-through': !showing,
                        'text-blue-500': showing,
                        'text-red-500': !showing}">
          Blueprint
      </div>
      </div>

</template>

<script>

  import blueprint_logo_png from "../../assets/img/logos/blueprint_logo.png"
  import toggle_off_svg from "../../assets/img/icons/toggle_off.svg"
  import toggle_off_hover_svg from "../../assets/img/icons/toggle_off_hover.svg"
  import toggle_on_svg from "../../assets/img/icons/toggle_on.svg"
  import toggle_on_hover_svg from "../../assets/img/icons/toggle_on_hover.svg"

  export default {

    name: "BlueprintFeatures",

    props: ['show_features', 'map'],
    
    emits: ['enable_featues'],
  
    data() {
      return {
        blueprint_logo_png,
        showing: true,
        toggle_on_svg,
        toggle_on_hover_svg,
        toggle_off_svg,
        toggle_off_hover_svg,
      }
    },
  
    methods:{

      swapImage(element, src){
        element.src = src;
      },

      toggleBlueprintLayer(){
        this.showing = !this.showing;
        if(this.showing){
          this.map.setLayoutProperty('features', 'visibility', 'visible');
        }else{
          this.map.setLayoutProperty('features', 'visibility', 'none');
        }
        this.$emit('enable_featues', this.showing);
      },
    }
  }

</script>

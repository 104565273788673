<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-model_comp_bg_eco_closed': !showing,
                'drop-shadow-md rounded bg-model_comp_bg_eco_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        title="Displays the Terrestrial Resilience Overlay"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-lg 
               2xl:text-xl"
        :class="{'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom,
                'cursor-pointer': hover_title && zoom >= overlay.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= overlay.source.minzoom ||
                                              showing)}"
        id="layer_terrestrial_resilience"
        @click="toggleLayer">Terrestrial Resilience 
        <span class="text-xs xl:text-sm 2xl:text-base">(TNC)</span>
      </div>
      <div class="text-xs xl:text-sm 2xl:text-lg -mt-0.5 ml-1 
                  col-span-9 col-start-2"
           :class="{'text-gray-300': zoom < overlay.source.minzoom,
                     'text-neutral-400': zoom >= overlay.source.minzoom}"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" 
          class="hover:underline">(TNC, 2016)</a>
        <span v-else>(TNC, 2016)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.source.minzoom">
        <input type="range" value="80" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-lg text-gray-700"
           v-show="showing && zoom >= overlay.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_terrestrial_resilience">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_terrestrial_resilience">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>
      <div class="col-span-10 col-start-2 h-4 mb-2 mx-1 text-lg text-gray-700"
           v-show="showing && zoom >= overlay.source.minzoom">Resilience Score
      </div>
      <div class="col-span-1 col-start-2 bg-t_resilience_5 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        5 - Highest
      </div>
      <div class="col-span-1 col-start-2 bg-t_resilience_4 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        4 - Above Average 
      </div>
      <div class="col-span-1 col-start-2 bg-t_resilience_3 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        3 - Average
      </div>
      <div class="col-span-1 col-start-2 bg-t_resilience_2 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        2 - Below Average
      </div>
      <div class="col-span-1 col-start-2 bg-t_resilience_1 h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        1 - Far Below Average
      </div>
      <div class="flex justify-center text-gray-700 items-center col-span-1 col-start-2 bg-white h-4 text-sm mt-2 mx-1
                  border-2 border-gray-400 border-dashed"
           v-show="showing && zoom >= overlay.source.minzoom">
        Clear
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-lg"
           v-show="showing && zoom >= overlay.source.minzoom">
        Not Rated (Urban)
      </div>
    </div>
  </div>

</template>

<script>

  import axios from 'axios';

  export default {

      name: "TerrestrialResilience",

      data(){
          return{
              codes: {
                0:  'No Scored',
                1: 'Far Below Average',
                2: 'Below Average',
                3: 'Average',
                4: 'Above Average',
                5: 'Highest',
              },
              data_error: '',
              data_name: 'terrestrial_resilience',
              hover_meta: false,
              hover_source: false,
              hover_title: false,
              label: 'Terrestrial Resilience - TNC',
              overlay: {
                'source': {
                  'name': 'terrestrial-resilience',
                  'type': 'vector',
                  'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                        + 'terrestrial-resilience/{z}/{x}/{y}.pbf'],
                  'minzoom': 13,
                  'maxzoom': 14,
                },
                'layer': {
                    'id': 'terrestrial-resilience',
                    'type': 'fill',
                    'source': 'terrestrial-resilience',
                    'source_layer': 'resilience.terrestrial_resilience',
                    'paint':  {
                        'fill-antialias': false,
                        'fill-color': [
                          'case',
                            ['>', ['get', 'value'], 819.1],
                            '#bd0026',
                            ['>', ['get', 'value'], -168.72],
                            '#f03b20',
                            ['>', ['get', 'value'], -662.63],
                            '#fd8d3c',
                            ['>', ['get', 'value'], -1156.54],
                            '#feb24c',
                            ['<', ['get', 'value'], -1156.54],
                            '#fed976',
                            '#ffffb2'
                        ],
                        'fill-opacity': .8
                      }
                  }
              },
              showing: false,
              source_data: {'metadata_url': '',
                            'source_url': '',
                            'description': ''}
          }
      },

      props:['show_features', 'label_layer_id', 'layers_to_load', 
             'map', 'map_loaded',  'reset', 'style_change', 
             'zoom'],

       emits: ['add', 'mounted', 'remove', 'popup'],

      mounted(){
        this.getLayerInfo();
        this.$emit('mounted', this.overlay.layer.id);
      },

      watch:{

        map_loaded: function(){
          this.addSource();
          if(this.layers_to_load.includes(this.overlay.layer.id)){
            this.addLayer();
            this.showing = true;
          }
        },


        reset: function(){
          this.reset_layer();
        },

        style_change: function(){
          this.addSource();
          if(this.showing){
            this.addLayer();
          }
        }
      },

      methods:{

        addInteractions(){
          this.removeInteractions();
          this.map.on('click', this.overlay.layer.id, this.handleLayerClick);
        },

        addLayer(){
          if(this.map.getLayer(this.overlay.layer.id)){
            return;
          }
          
          this.map.addLayer({
            'id': this.overlay.layer.id,
            'type': this.overlay.layer.type,
            'source': this.overlay.source.name, 
            'source-layer': this.overlay.layer.source_layer,
            'paint': this.overlay.layer.paint
          }, this.label_layer_id);

          if(this.map.getLayer('feature-borders')){
            this.map.moveLayer('feature-borders',this.label_layer_id);
          }

          this.addInteractions();
        },

        addSource(){
            if(this.map.getSource(this.overlay.source.name)){
                return;
            }
            
            this.map.addSource(this.overlay.source.name, {
                'type': this.overlay.source.type,
                'tiles': this.overlay.source.tiles,
                'minzoom': this.overlay.source.minzoom,
                'maxzoom': this.overlay.source.maxzoom,
            });
        },

        classifyValue(value){
          let cls = 0;

          if(value > 819.1){
            cls = 5;
          }else if(value >= -168.72){
            cls = 4;
          }else if(value >= -662.63){
            cls = 3;
          }else if(value >= -1156.54){
            cls = 2;
          }else if(value < -1156.54){
            cls = 1;
          }else{
            cls = 0;
          }

          return cls;
        },

        getLayerInfo(){
            let url = 'https://gspr34zib0.execute-api.us-east-1.';
                url += 'amazonaws.com/default/data-retriever?action=';
                url += `get_data_source_summary&data_name=${this.data_name}`;

            axios.get(url)
                .then(response => {
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                })
                .catch(error => {
                    this.data_error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';  
                });
        },

        handleLayerClick(e){
        
          const html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
              Terrestrial Resilience (TNC)</p><hr class="mb-1.5">
              <p class="text-left">Class 
              ${this.classifyValue(e.features[0].properties.value)} - 
              ${this.codes[this.classifyValue(e.features[0].properties.value)]}
              </p><p class="text-gray-500 pl-2">Raw Score: 
              (${e.features[0].properties.value})</p>`;

          this.$emit('popup', {'id': this.overlay.layer.id, 'html': html,
                   'coords': e.lngLat});
        },

        removeLayer(){
          this.removeInteractions();
          this.map.removeLayer(this.overlay.layer.id);
        },

        removeInteractions(){
        this.map.off('click', this.overlay.layer.id, this.handleLayerClick);
        },

        reset_layer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.layer.id);
            this.showing = false;
          }
        },

        setOpacity(e){
          let value = e.target.value;
          this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 
                                                                    value/100);
        },

        toggleLayer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.layer.id);
          }else{
            if(this.zoom >= this.overlay.source.minzoom){
              this.addLayer();
              this.$emit('add', this.overlay.layer.id)
            }
          }
            this.showing = !this.showing;
        }
      }
  }

</script>

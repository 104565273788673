<template>
  <div v-if="data_in" class="w-full h-full">

    <div class="w-full h-auto -mr-4 border-t border-gray-200 bg-white">
      <div class="w-full text-center text-xs lg:text-base xl:text-lg  
                  font-semibold text-gray-500 border-t border-gray-200 pt-2">
        Tax Records 
        <span v-if="this.link && !data['redirect']" 
          class="">
          (<a v-bind:href="link" 
              id="tax_link"
              target="_blank"
              @click="pushToAnalytics">Link</a>)
        </span>
      </div>
      <div v-if="data['message']"
          class="text-center text-gray-600 text-xs lg:text-base xl:text-lg  
                 px-6 mt-3">
        <span class="pl-2 text-gray-400">
          Server said: 
        </span>
        <span class="italic">
          {{data['message']}}
        </span>
        <span class="italic pl-2 text-blue-500 hover:underline">
          <a v-bind:href="link" target="_blank" v-if="link">
            {{this.feature_id}}
          </a>
        </span>
      </div>

      <div v-if="data['redirect']"
          class="text-left text-gray-600 text-xs lg:text-base xl:text-lg  
                 px-6 mt-3">
        <div class="italic">
          {{data['redirect']}}
        </div>
        <div class="italic pl-2 text-blue-500 hover:underline">
          <a v-bind:href="link" target="_blank"  v-if="link">
            {{this.feature_id}}
          </a>
        </div>
      </div>


      <div v-if="!this.data['message'] &&!this.data['redirect'] && this.data_in" 
          class="grid grid-cols-3 font-Open+Sans font-normal text-xs lg:text-base 
                  xl:text-lg py-2">
        <div class="col-span-1 text-gray-500 text-right pr-2">
          Location:
        </div>
        <div class="col-span-2 text-left pl-2 py-0.5 ">
          {{data['address']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Town:
        </div>
        <div class="col-span-2 text-left pl-2 py-0.5">
          {{data['town']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Owner:
        </div>
        <div class="col-span-2 text-left pl-2 py-0.5">
            {{data['owner']=='Current Owner'?'Redacted':data['owner']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 pt-0.5">
          Address:
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5">
          {{data['owner_address_1']}}
        </div>
        <div class="col-span-2 col-start-2 text-left pl-2 pb-0.5 
                  "
              v-if="data['owner_address_2']">
          {{data['owner_address_2']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Tax Class:
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5">
            ({{data['property_class']}}) {{data['description']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Zoning:
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5"
              v-if="data['zoning']">
          {{data['zoning']}}
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5"
              v-if="!data['zoning']">
          No Data
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Assessment:
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5">
          {{data['assessment']}}
        </div>
        <div class="col-span-1 text-gray-500 text-right pr-2 py-0.5">
          Taxes:
        </div>
        <div class="col-span-2 text-left pl-2 pt-0.5">
          {{data['taxes']}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/**
 * Gets MODIV tax data for a pams_pin (PROP = feature_id) using an ajax 
 * connection to an AWS lambda function named "modiv_record_retriever".  
 * Formats results as JSON.  Emits results as modiv_data.
 */

  import axios from 'axios';

  export default {
    name: 'ModivComponent',
    
    props: ['feature_id', 'restore_data'],
    emits: ['data_in', 'modiv_data'],

    watch: {
      /**
       * Method watches for change in component prop "feature-id" and initiates 
       * a method to get modiv data: getData(), or restore data from a saved 
       * parcel: restoreData()
       */
      feature_id: function() {
        if(!this.feature_id){
          this.reset();
          return;
        }
        //  0 == no feaature selected
        // -1 == a feature has been selected, but the pams_pin isn't known and 
        // data hasn't been generated.  
        if (this.feature_id == '0' || this.feature_id == '-1') {
          return;
        }
        if (this.restore_modiv_data) {
          this.restoreData();
        } else {
          this.getData();
        }
      }
    },
    /**
     * @description
     * @returns {any}
     */
    data() {
      return {
        data: {},
        descriptions: {
          "15B": "Other School Property",
          "15C": "Public Property",
          "15D": "Church and Charitable Property",
          "15E": "Cemeteries and Graveyards",
          "15F": "Other Exempt properties",
          "2": "Residential (four families or less)",
          "1": "Vacant Land",
          "3A": "Farm (Regular)",
          "4A": "Commercial",
          "4B": "Industrial",
          "4C": "Apartment",
          "5A": "Class I Railroad Property",
          "5B": "Class II Railroad Property",
          "6B": "Machinery, Apparatus or Equipment of" + "Petroleum Refineries",
          "6A": "Personal Property Telephone",
          "3B": "Farm (Qualified)",
          "15A": "Public School Property"
        },
        feature_type: '',
        link: '',
        message: '',
        data_in: false,
        url: 'https://gspr34zib0.execute-api.us-east-1.amazonaws.com/' 
             + 'default/modiv_record_retriever?pin='
      }
    },
    
    methods: {
      /**
       * @description
       */
      restoreData() {
        this.reset();
        this.setData(this.restore_modiv_data);
        this.data_in = true;
      },

      /**
       * @description
       */
      getData() {
        this.reset();
        if (this.feature_id.split('_').length < 3) {
          this.data_in = true;
          return;
        }
        const url = this.url + this.feature_id;

        axios.get(url)
        .then(response =>{
          const data = response.data;

          if (data.hasOwnProperty('message')) {
            this.data['message'] = data.message;
            if (data.hasOwnProperty('url')) {
              this.link = data.url;
              if(this.link.toLowerCase().includes('no data')){
                this.link = null;
              }
            }
          }else if(data.hasOwnProperty('redirect')){
            this.data['redirect'] = data.redirect;
            if (data.hasOwnProperty('url')) {
              this.link = data.url;
            }
          } else {
            this.setData(data);
          }
        }).
        catch((error) => {
          let data = {};
          data['message'] = 'Tax data error. Server said: ' + error;
          this.data = data;
        }).then(() =>{
          this.data_in = true;
          this.$emit('data_in', this.data_in);
          this.$emit('modiv_data', this.data);
          this.feature_type = 'parcel';
        });
      },

      pushToAnalytics(link){
        dataLayer.push({
          'event': 'tax_link_click',
          'tax_link': this.link
        });
      },

      /**
       * @description
       * @param {any} data
       */
      setData(data) {
        this.data['message'] = '';
        this.link = data.url;
        this.data['address'] = data.address ? data.address: "No Data";
        this.data['town'] = data.town ? data.town: "No Data";
        this.data['owner'] = data.owner ? data.owner: "No Data";
        
        //handle bad formatting in njpropertyrecords.com source data:
        this.data['owner_address_1'] = data.owner_address_1 ? 
                  data.owner_address_1.replace('City State Zip', ''): "No Data";
        this.data['owner_address_2'] = 
                        data.owner_address_2 ? data.owner_address_2: "No Data";
        if(this.data['owner_address_2'] == this.data['owner_address_1']){
          this.data['owner_address_2'] == '';
        }
        
        this.data['zoning'] = data.zoning ? data.zoning: null;
        if (data.assessment) {
          let a = data.assessment.replace('$', '').replaceAll(',', '');
          this.data['assessment'] = '$' + Number(a).toLocaleString('en-US');
          this.data['assessed_value'] = Number(a);
        }
        if (data.taxes) {
          let t = data.taxes.replaceAll('$', '').replaceAll(',', '').split('/');
          let a = []
          
          t.forEach(e =>{
            let n = Number(e.trim());
            if (this.data['assessed_value']) {
              if (n >= this.data['assessed_value']) {
                n = n / 100
              }
            }
            a.push('$' + n.toLocaleString('en-US'));
          });
          this.data['taxes'] = a.join(' / ');
        } else {
          this.data['taxes'] = null;
        }
        this.data['property_class'] = 
                                data.property_class ? data.property_class: "";
        this.data['description'] = 
                      this.descriptions[data.property_class] ? 
                      this.descriptions[data.property_class] : 'Code Unknown';
      },

      /**
       * @description
       */
      reset() {
        this.data_in = false;
        this.data = {};
        this.feature_type = '';
        this.link = '';
        this.message = '';
      }
    }
  }

</script>

<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
       :class="{'bg-ref_comp_bg_closed': !showing,
                'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < overlay.source.minzoom,
                'text-blue-700': showing && zoom >= overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= overlay.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        class="col-span-9 text-sm md:text-sm lg:text-base xl:text-xl 
               2xl:text-2xl"
        title="Displays the Statewide Sewer Service Area Overlay"
        :class="{'text-ref_comp_bg': showing,
                'text-ref_text': !showing,
                'cursor-pointer': hover_title && zoom >= overlay.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= overlay.source.minzoom ||
                                              showing)}"
        id="layer_sewer_service_area"
        @click="toggleLayer">Sewer Service Areas
      </div>
      <div class="text-xs xl:text-sm 2xl:text-lg -mt-0.5 ml-1 
                  col-span-9 col-start-2 text-ref_text_min"
      ><a v-bind:href="source_data.source_info_url" 
          target="_blank"
          title="Information"
          v-if="source_data.source_info_url"
          id="source_link" class="hover:underline">(NJDEP, 2023)</a>
        <span v-else>(NJDEP, 2023)</span>
      </div>
      <div title="Opacity" 
           class="text-left col-span-2 mr-4 ml-1" 
           v-show="showing && zoom >= overlay.source.minzoom">
        <input type="range" value="80" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
      </div>
      <div class="col-span-10 col-start-2 py-1 2xl:py-2 pr-5 text-xs md:text-sm 
                  lg:text-base 2xl:text-2xl text-gray-700"
           v-show="showing && zoom >= overlay.source.minzoom">
        {{ source_data.description }}
      </div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        <!-- <span class="col-span-6 mb-0.5 text-xs md:text-sm lg:text-base
                     2xl:text-2xl text-gray-500">
          Downloaded {{source_data.updated}}
        </span>
        <a target="_blank" 
           :href="source_data.source_info_url"
          @mouseover="hover_source = true" 
          @mouseout="hover_source = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_source,
                  'text-blue-500': !hover_source,
                  'cursor-pointer': hover_source,
                  'cursor-default': !hover_source,
                  'underline': hover_source}"
          id="source_sewer_service_area">
          Source
        </a> -->
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_sewer_service_area">
           Metadata
        </a>
      </div>
      <div class="col-span-12 col-start-2 text-gray-600 text-base mt-1"
             v-show="showing && zoom >= overlay.source.minzoom">
          Type 
        </div>
      <div class="col-span-1 col-start-2 bg-ssa_gw h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
           Ground Water Discharge
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_sw h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Surface Water Discharge
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_swgw h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Ground/Surface Water Discharge
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_ti h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Sanitary Subsurface Disposal
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_nd h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Non-Discharge
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_tbd h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        To be determined
      </div>
      <div class="col-span-1 col-start-2 bg-ssa_na h-4 mt-2 mx-1"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Not Applicable
      </div>
    </div>
  </div>

</template>

<script>

  import axios from 'axios';

  export default {

    name: "SewerServiceArea",

    data(){
      return{
        data_error: '',
        data_name: 'statewide_sewer_service_area',
        hover_meta: false,
        hover_source: false,
        hover_title: false,
        label: 'NJDEP Statewide Sewer Service Area',
        overlay: {
          'source': {
            'name': 'statewide_sewer_service_area',
            'type': 'vector',
            'antialias': false,
            'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                    + 'sewer-service/{z}/{x}/{y}.pbf'],
            'minzoom': 6,
            'maxzoom': 14,
          },
          'layer': {
              'id': 'sewer-service-area',
              'type': 'fill',
              'source': 'sewer-service-area',
              'source_layer': 'tiles.statewide_sewer_service_area',
              'paint':  {
                'fill-color': [
                  'match', ['get', 'type'],
                  'GW',
                  '#0000FF',
                  'DGW',
                  '#0000FF',
                  'GW (Ground Water)',
                  '#0000FF',
                  'SW',
                  '#0096FF',
                  'DSW',
                  '#0096FF',
                  'SW (Surface Water)',
                  '#0096FF',
                  'SW GW',
                  '#9A2A2A',
                  'TBD',
                  '#C70039',
                  'Non Discharge',
                  '#AAFF00',
                  'NA',
                  '#50C878',
                  'T1',
                  '#E1C16E',
                  '#ccc'
                ],
              }
            }
        },
        showing: false,
        source_data: {'metadata_url': '',
                      'source_url': '',
                      'description': ''},
        types: {
          'GW': 'Ground Water Discharge',
          'DGW': 'Ground Water Discharge',
          'GW (Ground Water)': 'Ground Water Discharge',
          'SW': 'Ground Water Discharge',
          'DSW': 'Ground Water Discharge',
          'SW (SURFACE WATER)': 'Surface Water Discharge',
          'SW GW': 'Surface/Ground Water Discharge',
          'GW SW':'Ground/Surface Water Discharge',
          'T1': 'Sanitary Subsurface Disposal',
          'NA': 'Not Applicable',
          'Non Discharge': 'None Discharge',
          'TBD': 'To Be Determined'
        }
      }
    },

    props:['show_features', 'label_layer_id', 'layers_to_load', 
          'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

     emits: ['add', 'mounted', 'remove', 'popup'],

    mounted(){
      this.getLayerInfo();
      this.$emit('mounted', this.overlay.layer.id);
    },

    watch:{  

      map_loaded: function(){
        this.addSource();
        if(this.layers_to_load.includes(this.overlay.layer.id)){
          this.addLayer();
          this.showing = true;
        }
      },

      reset: function(){
        this.reset_layer();
      },

      style_change: function(){
        this.addSource();
        if(this.showing){
          this.addLayer();
        }
      }
    },  

    methods:{

      addInteractions(){
        this.removeInteractions();
        this.map.on('click', this.overlay.layer.id, this.handleLayerClick);
      },

      addLayer(){
        if(this.map.getLayer(this.overlay.layer.id)){
          return;
        }
          
        this.map.addLayer({
          'id': this.overlay.layer.id,
          'type': this.overlay.layer.type,
          'source': this.overlay.source.name, 
          'source-layer': this.overlay.layer.source_layer,
          'paint': this.overlay.layer.paint
        }, this.label_layer_id);

        if(this.map.getLayer('feature-borders')){
          this.map.moveLayer('feature-borders',this.label_layer_id);
        }

        this.addInteractions();
      },

      addSource(){
          if(this.map.getSource(this.overlay.source.name)){
            return;
          }

          this.map.addSource(this.overlay.source.name, {
              'type': this.overlay.source.type,
              'tiles': this.overlay.source.tiles,
              'minzoom': this.overlay.source.minzoom,
              'maxzoom': this.overlay.source.maxzoom,
          });
      },

      getLayerInfo(){
          let url = 'https://gspr34zib0.execute-api.us-east-1.';
              url += 'amazonaws.com/default/data-retriever?action=';
              url += `get_data_source_summary&data_name=${this.data_name}`;

          axios.get(url)
              .then(response => {
                  if(response.hasOwnProperty('data')){
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                  }else{
                        this.data_error = 'Failed to get data from server.';  
                  }
              })
              .catch(error => {
                    this.data_error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';  
              });
        },

      handleLayerClick(e){
        
        let html = `<p class="font-semibold text-stone-900 drop-shadow-sm"> 
                      NJDEP Sewer Service Area</p><hr class="mb-1.5">
                      <table class="w-full m-1"><tr>`
            html +=   e.features[0].properties.facname?`<th>Name:</th>
                      <td>${e.features[0].properties.facname}</td></tr>`:'';
            html +=   e.features[0].properties.type?`<th>Type:</th>
                      <td>${this.types[e.features[0].properties.type]}</td></tr>`:'';
            html +=   e.features[0].properties.wqmp?`<th>Plan:</th>
                      <td>${e.features[0].properties.wqmp}</td></tr>`:'';
            html +=   e.features[0].properties.wmp?`<th>&nbsp;</th>
                      <td>${e.features[0].properties.wmp}</td></tr>`:'';
            html +=   e.features[0].properties.wmpagcy?`<th>Agency:</th>
                      <td>${e.features[0].properties.wmpagcy}</td></tr>`:'';
            html +=   e.features[0].properties.njpdes?`<th>Permit:</th>
                      <td>${e.features[0].properties.njpdes}</td></tr>`:'';

        this.$emit('popup', {'id': this.overlay.layer.id, 'html': html,
                   'coords': e.lngLat});
      },

      removeLayer(){
        this.removeInteractions();
        this.map.removeLayer(this.overlay.layer.id);
      },

      removeInteractions(){
        this.map.off('click', this.overlay.layer.id, this.handleLayerClick);
      },

      reset_layer(){
        if(this.showing){
          this.removeLayer();
          this.$emit('remove', this.overlay.layer.id);
          this.showing = false;
        }
      },

      setOpacity(e){
        let value = e.target.value;
        this.map.setPaintProperty(this.overlay.layer.id, 'fill-opacity', 
                                                                  value/100);
      },

      toggleLayer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.layer.id);
          }else{
            if(this.zoom >= this.overlay.source.minzoom){
              this.addLayer();
              this.$emit('add', this.overlay.layer.id);
            }
          }
          this.showing = !this.showing;
      }
    }
  }

</script>

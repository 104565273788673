<template>
  
  <div class="w-full h-auto text-xs md:text-sm lg:text-base xl:text-xl 
              2xl:text-2xl pt-0.5 pb-1.5 2xl:my-2 rounded-[0.4375rem]"
        :class="{'bg-ref_comp_bg_closed': !showing,
          'drop-shadow-md rounded bg-ref_comp_bg_open': showing}">
    <div title="Overlay" class="mx-1 grid grid-cols-12 text-left"
         :class="{'mb-8': showing}">
      <div 
        class=" col-span-1 text-blue-700 text-center"
        :class="{'opacity-100': showing,
                'opacity-0': !showing,
                'text-gray-300': zoom < this.overlay.source.minzoom,
                'text-blue-700': showing && zoom >= 
                                                    this.overlay.source.minzoom,
                'text-blue-400': !showing && zoom >= 
                                                this.overlay.source.minzoom}">
        &#10003;
      </div>
      <div 
        @mouseover="hover_title = true" 
        @mouseout="hover_title = false"
        class="col-span-8 text-sm md:text-sm lg:text-base xl:text-xl 
               2xl:text-2xl"
        :class="{'text-ref_text': zoom < this.overlay.source.minzoom,
                'text-ref_text': showing && zoom >= 
                                                    this.overlay.source.minzoom,
                'text-ref_text': !showing && zoom >= 
                                                    this.overlay.source.minzoom,
                'cursor-pointer': hover_title && zoom >= 
                                                    this.overlay.source.minzoom,
                'cursor-default': !hover_title,
                'underline': hover_title && (zoom >= 
                                    this.overlay.source.minzoom || showing)}"
        @click="toggleLayer">Counties
      </div>
      <div title="Opacity" 
           class="text-left col-span-3 mr-4 ml-1" 
           v-show="showing">
        <input type="range" value="80" 
              class="h-1 w-full bg-gray-200 rounded-lg appearance-none 
                     cursor-pointer range-sm dark:bg-gray-700"
              @change="setOpacity($event)">
        <input type="checkbox" id="show-labels" v-model="show_labels">
        <label for="show-labels" 
               class="text-xs md:text-sm text-blue-700"
               @click="show_labels = !show_labels"> Labels</label>
      </div>
      <div class="col-span-1">&nbsp;</div>
      <div class="col-span-12 col-start-2 grid grid-cols-6 py-1 text-xs
                  md:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        <a target="_blank" 
           :href="source_data.metadata_url"
          @mouseover="hover_meta = true" 
          @mouseout="hover_meta = false"
          class="col-span-3"
          :class="{'text-blue-700': hover_meta,
                  'text-blue-500': !hover_meta,
                  'cursor-pointer': hover_meta,
                  'cursor-default': !hover_meta,
                  'underline': hover_meta}"
          id="metadata_parcels">
           Metadata
        </a>
        <span class="col-span-2">&nbsp;</span>
      </div>

      <div class="col-span-1 col-start-2 bg-municipal_border h-1 mx-1 mt-4"
           v-show="showing && zoom >= overlay.source.minzoom">
      </div>
      <div class="h-4 mt-2 2xl:mt-0 pl-2 col-span-10 align-middle text-xs 
                  md:text-sm lg:text-sm xl:text-base 2xl:text-2xl"
           v-show="showing && zoom >= overlay.source.minzoom">
        Border
      </div>

    </div>
  </div>

</template>

<script>

  import axios from 'axios';

  export default {

      name: "CountyBorders",

      data(){
        return{
          data_error: '',
          data_name: 'counties',
          hover_meta: false,
          hover_source: false,
          hover_title: false,
          label: 'Counties Borders',
          labels:{
            'source':{
              'name': 'county-labels',
              'type': 'geojson',
              'data': 'https://blueprint-explorer.s3.amazonaws.com/labels/'
                      + 'counties.json'
            },
              'layer': {
              'id': 'labels',
              'type': 'symbol',
              'source': 'county-labels',
              'style': { 'font-scale': 1.0,
                          'text-color': '#6a6a6a',
                          'text-font': [
                            'literal',
                            ["Open Sans Regular", "Arial Unicode MS Regular"]
                          ]},
              'label_column': 'name'
            }
          },
          overlay: {
            'source': {
              'name': 'counties-borders',
              'type': 'vector',
              'tiles': ['https://d7wi8peueokva.cloudfront.net/'
                      + 'county-borders/{z}/{x}/{y}.pbf'],
              'minzoom': 6,
              'maxzoom': 16,
            },
            'layer': {
                  'id': 'counties-borders',
                  'type': 'line',
                  'source': 'counties-borders',
                  'source_layer': 'tiles.county_borders',
                  'paint':  {
                    'line-width': 1,
                    'line-color': '#3b3a3a',
                    'line-opacity': .8
                  }
                }
          },
          showing: false,
          show_labels: false,
          source_data: {'metadata_url': '',
                        'source_url': '',
                        'description': ''}
        }
      },

      props:['show_features', 'label_layer_id', 'layers_to_load', 
             'map', 'map_loaded', 'reset', 'style_change', 'zoom'],

      emits: ['add', 'mounted', 'remove'],

      mounted(){
        this.getLayerInfo();
        this.$emit('mounted', this.overlay.layer.id);
      },

      watch:{

        map_loaded: function(){

          this.addSources();
          if(this.layers_to_load.includes(this.overlay.layer.id)){
            this.addLayer();
            this.showing = true;
          }
        },

        reset: function(){
          this.reset_layer();
        },

        show_labels: function(){
          this.toggleLabels();
        },

        style_change: function(){
          this.addSources();
          if(this.showing){
            this.addLayer();
          }
        }
      },

      methods:{

        addLabels(){

          this.map.addLayer({
            'id': this.labels.layer.id,
            'type': this.labels.layer.type,
            'source': this.labels.source.name
          });

          this.map.setLayoutProperty(
            'labels',
            'text-field',
            ['format', 
              ['get', this.labels.layer.label_column], this.labels.layer.style
            ] 
          );
        },

        addLayer(){
          if(this.map.getLayer(this.overlay.layer.id)){
            return;
          }

          this.map.addLayer({
            'id': this.overlay.layer.id,
            'type': this.overlay.layer.type,
            'source': this.overlay.source.name, 
            'source-layer': this.overlay.layer.source_layer,
            'paint': this.overlay.layer.paint
          }, this.label_layer_id);

          if(this.map.getLayer('feature-borders')){
            this.map.moveLayer('feature-borders',this.label_layer_id);
          }
        },

        addOverlay(){
          this.addLayer();
          this.showing = true;
          this.$emit('add', this.overlay.layer.id);
        },

        addSources(){
            if(this.map.getSource(this.overlay.source.name)){
                return;
            }
            
            this.map.addSource(this.overlay.source.name, {
                'type': this.overlay.source.type,
                'tiles': this.overlay.source.tiles,
                'minzoom': this.overlay.source.minzoom,
                'maxzoom': this.overlay.source.maxzoom,
            });

            this.map.addSource(this.labels.source.name, {
              'type': this.labels.source.type,
              'data': this.labels.source.data
            });
        },

        getLayerInfo(){
            let url = 'https://gspr34zib0.execute-api.us-east-1.';
                url += 'amazonaws.com/default/data-retriever?action=';
                url += `get_data_source_summary&data_name=${this.data_name}`;

            axios.get(url)
                .then(response => {
                    if(response.data.hasOwnProperty('message')){
                        this.data_error = response.data.message;  
                    }else{
                        this.source_data = response.data;
                    }
                })
                .catch(error => {
                    this.data_error  = 'Feature request error.' 
                                    + ' Server said: ' + error?error:'Nothing';  
                });
        },

        removeLabels(){
          this.show_labels = false;
          if(this.map.getLayer(this.labels.layer.id)){
            this.map.removeLayer(this.labels.layer.id);
          }
        },

        removeLayer(){
          this.map.removeLayer(this.overlay.layer.id);
          this.removeLabels();
        },

        reset_layer(){
          if(this.showing){
            this.removeLayer();
            this.$emit('remove', this.overlay.layer.id);
            this.showing = false;
          }
        },

        setOpacity(e){
          let value = e.target.value;
          this.map.setPaintProperty(this.overlay.layer.id, 'line-opacity', 
                                                                    value/100);
        },

        toggleLabels(){
          if(!this.show_labels){
              this.removeLabels();
            }else{
              this.addLabels()
            }
        },

        toggleLayer(){
          if(this.showing){
            this.showing = false;
            this.removeLayer();
            this.removeLabels();
            this.$emit('remove', this.overlay.layer.id);
          }else{
            if(this.zoom >= this.overlay.source.minzoom){
              this.showing = true;
              this.addLayer();
              this.$emit('add', this.overlay.layer.id)
            }
          }
        }
      }
  }

</script>

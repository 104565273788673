<template>

  <!-- TOP BANNER  - MEDIUM SCREEN+ -->
    
  <div class="hidden md:grid md:grid-cols-7 h-12 md:h-14 2xl:h-24 
              relative place-content-bottom z-10 bg-white border-b
              border-gray-400 align-middle font-Open+Sans"
      id="title">

      <div class="col-span-5 pl-2 py-1 2xl:py-1.5 text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl
                text-gray-500 font-medium flex items-end 
                justify-start ">
          <a href="./" title="New Jersey Conservation Blueprint" class=""
              id="explorer_logo">
              <img :src="plus_logo_svg"
                  alt="Conservation Blueprint Logo" 
                  class="h-8 lg:h-10 xl:h-12 2xl:h-20 mx-auto inline">
          </a>
          <h1 id="imageLink" class="pl-6">Conservation Blueprint
          </h1>
      </div>
      <div class="col-span-2 pr-4 pb-1.5 2xl:pb-3 text-sm lg:text-base xl:text-xl
                2xl:text-2xl text-gray-500 font-medium flex items-end
                justify-end">
          <span class="pt-1">engage. protect. restore.</span>
      </div>
  </div>


  <!-- MENU SECTION (TAB BAR) -->

  <div class="grid grid-cols-6 md:grid-cols-6 relative col-span-2 z-10
              bg-slate-100 font-Open+Sans
              bg-opacity-80"
      :class="menu_width_class"
      v-show="show_content">
      <div class="md:hidden flex col-span-1 h-14 md:h-20 md:p-2 bg-slate-100">
          <a href="./" title="New Jersey Conservation Blueprint" 
            class="my-auto mx-auto"
            id="explorer_logo">
            <img :src="plus_logo_svg"
                alt="Conservation Blueprint Logo" 
                class="inline h-9 mx-auto my-auto md:h-20 lg:h-5/6 lg:w-5/6 lg:pt-0">   
          </a>
      </div>

      <!-- SEARCH MENU ITEM -->

      <div class="col-span-1 col-start-2 md:col-start-1 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-25 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  "
          title="Search for a place or parcel">
          <div @click="changeSelection($event)"
              :data-idx="getMenuIndex('search')"
              class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                    rounded-full"
              :class="{'bg-blue-600':menu_selection==getMenuIndex('search'),
                        'bg-blue-100':menu_selection!=getMenuIndex('search'),
                        'hover:bg-blue-400':menu_selection!=getMenuIndex('search')}">  
              <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                  alt="Opens Conservation Blueprint search" 
                  :src="menu_selection==getMenuIndex('search')?
                        search_selected_svg:search_svg"
                  :data-idx="getMenuIndex('search')"
                  @mouseover="swapImage($event.target, search_selected_svg)"
                  @mouseleave="swapImage($event.target, search_svg)"
              >
          </div> 
          <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                      text-extrabold h-4 md:h-6"
              @click="changeSelection($event)" 
              :data-idx="getMenuIndex('search')">
              <h2 :data-idx="getMenuIndex('search')"
                  :class="menu_selection==getMenuIndex('search')?'underline': ''">
                  Search
              </h2>
          </div>
      </div>
    
      <!-- PIN(PARCEL) MENU ITEM -->

      <div class="col-span-1 col-start-3 md:col-start-2 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-32 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  "
            aria-labelledby="View information about a selected parcel"
            role="tooltip">
          <div @click="changeSelection($event)"
              :data-idx="getMenuIndex('info-panel')"
              class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                      rounded-full"
              :class="{'bg-blue-600':menu_selection==getMenuIndex('info-panel'),
                        'bg-blue-100':feature_id=='0' && menu_selection!=getMenuIndex('info-panel'),
                        'bg-blue-200':feature_id!='0' && menu_selection!=getMenuIndex('info-panel'),
                        'hover:bg-blue-400':menu_selection!=getMenuIndex('info-panel')}">  
              <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                  alt="Opens Conservation Blueprint parcel information" 
                  :src="menu_selection==getMenuIndex('info-panel')? feature_selected_svg:feature_svg"
                  :data-idx="getMenuIndex('info-panel')"
                  @mouseover="swapImage($event.target, feature_selected_svg)"
                  @mouseleave="swapImage($event.target, feature_svg)"
              >
          </div>
          <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                      text-extrabold h-4 md:h-6"
              @click="changeSelection($event)" 
              :data-idx="getMenuIndex('info-panel')">
              <h2 
                  :class="menu_selection==getMenuIndex('info-panel')?'underline': ''"
                  :data-idx="getMenuIndex('info-panel')">
                  Parcel
              </h2>
          </div>
      </div>
    
      <!-- LAYERS MENU ITEM -->

      <div class="col-span-1 col-start-4 md:col-start-3 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-32 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  ">
          <div @click="changeSelection($event)" 
              :data-idx="getMenuIndex('overlays')"
              class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                      rounded-full"
              :class="{'bg-blue-600':menu_selection==getMenuIndex('overlays'),
                        'bg-blue-100':overlay_count=='0' && menu_selection!=getMenuIndex('overlays'),
                        'bg-blue-200':overlay_count!='0' && menu_selection!=getMenuIndex('overlays'),
                        'hover:bg-blue-400':menu_selection!=getMenuIndex('overlays')}">  
              <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                  alt="Opens Conservation Blueprint layer menu" 
                  :src="menu_selection==getMenuIndex('overlays')?layers_selected_svg:layers_svg"
                  :data-idx="getMenuIndex('overlays')"
                  @mouseover="swapImage($event.target, layers_selected_svg)"
                  @mouseleave="swapImage($event.target, layers_svg)">
          </div>
          <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                      text-extrabold h-4 md:h-6"
              @click="changeSelection($event)" 
              :data-idx="getMenuIndex('overlays')"
          >
              <h2
                  :class="menu_selection==getMenuIndex('overlays')?'underline': ''"
                  :data-idx="getMenuIndex('overlays')"
                  >Layers
              </h2>
          </div>
      </div>

      <!-- FILTERS MENU ITEM -->

      <div class="col-span-1 col-start-5 md:col-start-4 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-32 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  ">
          <div @click="changeSelection($event)" 
              :data-idx="getMenuIndex('filters')"
              class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                      rounded-full"
              :class="{ 'bg-blue-600':menu_selection==getMenuIndex('filters'),
                'bg-blue-100':filter_count=='0' && menu_selection!=getMenuIndex('filters'),
                'bg-blue-200':filter_count!='0' && menu_selection!=getMenuIndex('filters'),
                'hover:bg-blue-400':menu_selection!=getMenuIndex('filters')}">  
              <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                  :src="menu_selection==getMenuIndex('filters')?filter_selected_svg:filter_svg"
                  alt="Opens Conservation Blueprint filters menu" 
                  :data-idx="getMenuIndex('filters')"
                  @mouseover="swapImage($event.target, filter_selected_svg)"
                  @mouseleave="swapImage($event.target, filter_svg)">
          </div>
          <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                      text-extrabold h-4 md:h-6"
                @click="changeSelection($event)" :data-idx="getMenuIndex('filters')"
          >
              <h2
                  :class="menu_selection==getMenuIndex('filters')?'underline': ''"
                  :data-idx="getMenuIndex('filters')"
                  >Filters
              </h2>
          </div>
      </div>
    
      <!-- SAVED MENU ITEM -->

      <div class="col-span-1 col-start-6 md:col-start-5 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-32 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  ">
          <div @click="changeSelection($event)" 
              :data-idx="getMenuIndex('bookmarks')"
              class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                    rounded-full"
              :class="{'bg-blue-600':menu_selection==getMenuIndex('bookmarks'),
                        'bg-blue-100':saved_feature_count=='0' && menu_selection!=getMenuIndex('bookmarks'),
                        'bg-blue-200':saved_feature_count!='0' && menu_selection!=getMenuIndex('bookmarks'),
                        'hover:bg-blue-400':menu_selection!=getMenuIndex('bookmarks')}">  
              <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                  :src="menu_selection==getMenuIndex('bookmarks')?bookmarks_selected_svg:bookmarks_svg"
                  alt="Opens Conservation Blueprint bookmarks list" 
                  :data-idx="getMenuIndex('bookmarks')"
                  @mouseover="swapImage($event.target, bookmarks_selected_svg)"
                  @mouseleave="swapImage($event.target, bookmarks_svg)">
          </div>
          <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                    text-extrabold h-4 md:h-6"
              @click="changeSelection($event)" 
              :data-idx="getMenuIndex('bookmarks')">
              <h2 :class="menu_selection==getMenuIndex('bookmarks')?'underline': ''"
                  :data-idx="getMenuIndex('bookmarks')"
                  >Saved
              </h2>
          </div>
      </div>

      <!-- SETTINGS MENU ITEM -->
      <div class="col-span-1 col-start-7 md:col-start-6 text-center h-15 md:h-20 
                  lg:h-20 2xl:h-32 cursor-pointer pt-3 2xl:pt-5 pb-3 
                  "
          @click="changeSelection($event)" 
          :data-idx="getMenuIndex('settings')">
          <div class="pr-0.5 md:pr-5" @click="toggleBlueprintLayer">
              <span class="flex mx-auto w-6 h-6 md:w-10 md:h-10 2xl:h-14 2xl:w-14
                            rounded-full"
                  :class="{'bg-blue-600':menu_selection==getMenuIndex('settings'),
                            'bg-blue-100':menu_selection!=getMenuIndex('settings'),
                            'hover:bg-blue-400':menu_selection!=getMenuIndex('settings')}">
                  <img  class="w-4 md:w-7 lg:w-6 2xl:w-8 mx-auto my-auto"
                      :src="menu_selection==getMenuIndex('settings')?settings_selected_svg:settings_svg"
                      alt="Opens Conservation Blueprint settings menu" 
                      :data-idx="getMenuIndex('settings')"
                      @mouseover="swapImage($event.target, settings_selected_svg)"
                      @mouseleave="swapImage($event.target, settings_svg)">
              </span>
              <div class="text-xs md:text-sm lg:text-base 2xl:text-xl text-blue-500 
                          text-extrabold block -ml-1 h-4 md:h-6"
                  @click="changeSelection($event)" >
                  <h2>Settings</h2>
              </div>
          </div>
      </div>
      <!-- END OF SETTING -->
  </div>
  <!-- END MENU -->

<!-- START XTRA ARROW BUTTON -->
  <!-- Used to display arrow button after its pressed and info panel is minimized -->
   
  <div class="relative z-20 bg-white h-0 md:h-screen md:left-0"
       :class="content_width_class"
       id="content-container"
       v-show="!show_content">

    <!-- SIDEBAR TOGGLE HANDLE -->
    <div class="absolute -mr-6 md:-mt-24 top-5 right-0 z-20 bg-white
                rounded-r-lg h-10 w-6 shadow-md"
          :style="{'top': `${window_height/2}px`}"
          id="control_sidebar" 
          @click="toggleContentVisibility(false)"
          @mouseenter="show_sidebar_prompt = true"
          @mouseleave="show_sidebar_prompt = false">
      <img :src="toggle_png"
            alt="Shows Conservation Blueprint sider panel" 
            class="h-7 w-7 mt-1.5 duration-200 transition-all"
            :class="{'rotate-180': !show_content}">
    </div>
  </div>
<!-- END XTRA ARROW BUTTON -->

  <!-- SIDEBAR -->
  
  <div class="relative z-20 bg-white h-0 md:h-screen md:left-0 "
       :class="content_width_class"
       id="content-container"
       v-show="show_content">

    <!-- SIDEBAR TOGGLE HANDLE -->
    <div class="absolute -mr-6 md:-mt-24 top-5 right-0 z-20 bg-white
                rounded-r-lg h-10 w-6 shadow-md"
          :style="{'top': `${window_height/2}px`}"
          id="control_sidebar" 
          @click="toggleContentVisibility(false)"
          @mouseenter="show_sidebar_prompt = true"
          @mouseleave="show_sidebar_prompt = false">
      <img :src="toggle_png"
            alt="Shows Conservation Blueprint sider panel" 
            class="h-7 w-7 mt-1.5 duration-200 transition-all"
            :class="{'rotate-180': !show_content}">
    </div>
   
    <!-- END SIDEBAR TOGGLE HANDLE --> 
    
    <!-- INFO PANEL -->

    <section id="info-section"
              class="content-block hidden"
              :data-idx="getMenuIndex('info-panel')">
      <info-panel v-bind:blueprint_status = "blueprint_status"
                  v-bind:feature_id = "feature_id"
                  v-bind:info_marker_coords = "info_marker_coords"
                  v-bind:panel_height = "panel_height"
                  v-bind:parcel_on_click="parcel_on_click"
                  v-bind:restore_feature_data = "restore_feature_data"
                  v-bind:selected = "selected"
                  @close="removeInfoPanel"
                  @current_feature="updateCurrentFeature"
                  @data_in="updateBlueprintDataStatusWithDelay"
                  @data_restored="setRestoreFeatureData"
                  @parcel_on_click="updateParcelClick"
                  @print="printFeature"
                  @save_feature="saveFeature"
                  @unsave_feature="unsaveFeature">
      </info-panel>
    </section>

    <!-- END INFO PANEL -->
    
    <!-- SAVED PANEL -->

    <section id="bookmark-section"
              class="content-block hidden"
              :data-idx="getMenuIndex('bookmarks')">
      <bookmarks
        v-bind:panel_height = "panel_height"
        v-bind:saved_parcels = "saved_parcels"
        @print="printFeature"
        @print_multi_parcel="printMultiParcelReport"
        @restore="restoreFeature"
        @unsave="unsaveFeature">

      </bookmarks>
    </section>
     
    <!-- END SAVED PANEL -->

    <!-- OVERLAYS PANEL -->

    <section  class="content-block hidden"
              id="overlay-section"
              :data-idx="getMenuIndex('overlays')">
              
      <div class="relative flex text-center mx-1.5 text-blue-500 text-base
                    xl:text-2xl font-Open+Sans "
            v-show="show_content">

        <div @click="()=>show_component_overlays = true" 
            class="flex-grow relative h-12 xl:h-16 p-4 rounded-t-xl mt-2
                 hover:bg-blue-50 shadow-inner border-l border-t border-r 
                 border-blue-200 "
            :class="{'border-b':!show_component_overlays,
                     'border-blue-200':!show_component_overlays,
                     'font-semibold':show_component_overlays}"
            >Model Components
        </div> 

        <div @click="()=>show_component_overlays = false"
            class="flex-grow relative h-12 xl:h-16 p-4 rounded-t-xl mt-2
                 hover:bg-blue-50 shadow-inner border-l border-t border-r 
                 border-blue-200"
            :class="{'border-b':show_component_overlays,
                     'border-blue-200':show_component_overlays,
                     'font-semibold':!show_component_overlays}"
            >Reference</div>
      </div>
        <component-overlays
          v-show="show_component_overlays"
          v-bind:basemap_changed = "basemap_changed"
          v-bind:show_features = "show_features"
          v-bind:label_layer_id = "label_layer_id"
          v-bind:layers_to_load = "overlays"
          v-bind:map = "map"
          v-bind:mapbox_token = "mapbox_token"
          v-bind:map_loaded = "map_loaded"
          v-bind:panel_open = "panel_open"
          v-bind:small_screen = "small_screen"
          v-bind:panel_height = "panel_height"
          v-bind:parcel_on_click = "parcel_on_click"
          v-bind:style_change = "style_change"
          v-bind:zoom = "zoom"
          @all_component_layers_loaded = "updateAllLayersLoaded"
          @close_panel="closePanel"
          @overlays="updateOverlays"
          @parcel_on_click="updateParcelClick"
          @popup="updateOverlayPopup">
        </component-overlays>
        <reference-overlays
          v-show="!show_component_overlays"
          v-bind:basemap_changed = "basemap_changed"
          v-bind:show_features = "show_features"
          v-bind:label_layer_id = "label_layer_id"
          v-bind:layers_to_load = "overlays"
          v-bind:map = "map"
          v-bind:mapbox_token = "mapbox_token"
          v-bind:map_loaded = "map_loaded"
          v-bind:panel_height = "panel_height"
          v-bind:panel_open = "panel_open"
          v-bind:parcel_on_click = "parcel_on_click"
          v-bind:small_screen = "small_screen"
          v-bind:style_change = "style_change"
          v-bind:zoom = "zoom"
          v-bind:x="'1'"
          @all_reference_layers_loaded = "updateAllLayersLoaded"
          @close_panel="closePanel"
          @overlays="updateOverlays"
          @parcel_on_click="updateParcelClick"
          @popup="updateOverlayPopup">
        </reference-overlays>
    </section>

    <!-- END OVERLAYS PANEL -->

    <!-- FILTERS PANEL -->
        
    <section class="content-block hidden"
              id="filter-section"
              :data-idx="getMenuIndex('filters')">
      <filter-panel
        v-bind:features = "features"
        v-bind:feature_id = "feature_id"
        v-bind:panel_height = "panel_height"
        v-bind:open_space = "openspace_status"
        v-bind:selected = "selected"
        v-bind:state_to_restore = "state_to_restore"
        v-bind:symbology = "symbology"
        v-bind:update_legend = "update_legend"
        v-bind:zoom_threshold_met = "zoom_threshold_met"
        @filters = "updateFilters"
        @filter_count = "updateFilterCount"
        @show_legend = "showLegend">
      </filter-panel>
    </section>

    <!-- END FILTERS PANEL -->

    <!-- SETTINGS PANEL -->
        
    <section class="content-block hidden"
              id="options-section"
              :data-idx="getMenuIndex('settings')">
      <blueprint-settings
        v-bind:map = "map"
        v-bind:parcel_on_click = 'parcel_on_click'
        @enable_featues = "enableFeatues"     
        @include_openspace = "updateOpenspaceStatus"
        @parcel_on_click = "updateParcelClick">
      </blueprint-settings>
    </section>

    <!-- END OPTIONS PANEL -->

    <!-- SEARCH PANEL-->

    <section id="search-section"
             class="content-block relative bg-white hidden"
             :data-idx="getMenuIndex('search')">
      <div class="md:pb-0 md:pt-6 mr-5 md:mr-10 md:ml-2">
        <mapbox-places
          v-show="show_content"
          v-bind:map = "map"
          v-bind:map_loaded = "map_loaded"
          v-bind:mapbox_token = "mapbox_token"
          v-bind:style_change = "style_change">
        </mapbox-places>
        <div class="-mt-1 md:mt-4"></div>
        <parcel-search
          v-show="show_content"
          @parcel="dropMarker">
        </parcel-search>
        <div class="bg-white text-center">
          <span class="text-base text-cbp opacity-10">
            engage. protect. restore.
          </span>
          <img :src="blueprint_logo_footer_svg"
               alt="Conservation Blueprint footer logo" 
                class="pt-3 opacity-10 w-7/12 block mx-auto">
        </div>
      </div>
     </section>

    <!-- END SEARCH PANEL-->

    <div class="h-9 text-center bg-white flex content-center items-end"
          v-if="small_screen"
          v-show="panel_open && menu_selection != 2"
        @click="closePanel">
        <div class="h-5 py-2 w-12 inline align-middle my-auto mx-auto
                    bg-gray-300 hover:bg-blue-400  rounded-full">
            <img :src="down_svg" class="inline align-top w-5 h-5 -mt-2"
            alt="Hides Conservation Blueprint side panel">
        </div>
    </div>
  </div>

  <!-- END SIDEBAR -->

  <!-- MAP -->

  <div class="absolute mx-auto top-14 md:top-12 xl:top-14 mt-2 md:mt-0 inset-y-0 
              w-full z-0"
        id="map-container">
      <div class="z-50 overflow-hidden bg-transparent
                  flex flex-col items-center justify-center"
          :class="{ 'hidden': !loading }">
        <div class="flex w-16 h-16 rounded-full bg-blue-800
                    bg-opacity-75 border border-white">
          <div class="bg-transparent text-white text-right m-auto
                      animate-spin pr-2">
            ----&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>

      <map-panel
        v-bind:all_layers_loaded = "all_layers_loaded"
        v-bind:break_points = "break_points"
        v-bind:cb_min = "cb_min"
        v-bind:change = "change"
        v-bind:default_style = "default_style"
        v-bind:default_view = "default_view"
        v-bind:feature_id = "feature_id"
        v-bind:show_features = "show_features"
        v-bind:feature_opacity = "feature_opacity"
        v-bind:has_legend = "has_legend"
        v-bind:filters = "filters"
        v-bind:info_marker_coords = "info_marker_coords"
        v-bind:info_panel = "info_panel"
        v-bind:map_bounds = "map_bounds"
        v-bind:map_opacity = "map_opacity"
        v-bind:mapbox_token = "mapbox_token"
        v-bind:municipality = "municipality"
        v-bind:openspace_status = "openspace_status"
        v-bind:overlays = "overlays"
        v-bind:parcel_click = "parcel_click"
        v-bind:parcel_size_min = "parcel_size_min"
        v-bind:saved_feature_ids = "saved_feature_ids"
        v-bind:selected = "selected"
        v-bind:show_content = "show_content"
        v-bind:small_screen = "small_screen"
        v-bind:state_to_restore = "state_to_restore"
        v-bind:selection_codes = "selection_codes"
        @add_marker = "dropMarker"
        @basemap_changed = "handleBasemapChange"
        @feature_opacity = "updateFeatureOpacity"
        @features = "updateFeatures"
        @loading_features = "updateLoading"
        @label_layer_id = "setLabelLayerID"
        @map = "setMap"
        @map_loaded = "updateMapLoaded"
        @zoom_threshold_met="updateZoomThresholdMet"
        @model_change="updateModelSelection"
        @style_change="updateStyleChange"
        @update_legend="updateLegend"
        @zoom="updateZoom">
    </map-panel>
  </div>

  <!-- END MAP -->

</template>

<script>
  /**
   * The main compponent of the Blueprint+ app.
   * 
   * @author Mark E. Corbalis <markcorbalis@gmail.com>
   * @copyright Mark E. Corbalis 2023
   * @version 1.1.0.1
   * @displayName Bluepint+ Main
   */
  
  import back_svg from "../assets/img/icons/back.svg"
  import BlueprintFeatures from "./overlays/BlueprintFeatures"
  import BlueprintSettings from './BlueprintSettings.vue';
  import blueprint_logo_footer_svg from 
                                "../assets/img/logos/blueprint_logo_footer.svg"
  import bookmarks_svg from "../assets/img/icons/bookmarks.svg"
  import bookmarks_hover_svg from "../assets/img/icons/bookmarks_hover.svg"
  import bookmarks_selected_svg 
                              from "../assets/img/icons/bookmarks_selected.svg"
  import BookmarkComponent from './BookmarkComponent.vue';
  import blueprint_logo_png 
                              from "../assets/img/logos/blueprint_logo.png"
  import ComponentOverlays from './ComponentOverlays.vue'
  import down_svg from "../assets/img/icons/down_arrow.svg"
  import feature_svg from "../assets/img/icons/feature.svg"
  import feature_hover_svg from "../assets/img/icons/feature_hover.svg"
  import feature_selected_svg from "../assets/img/icons/feature_selected.svg"
  import {FeatureGetter} from "../assets/js/FeatureGetterClass"
  import filter_svg from "../assets/img/icons/filter.svg"
  import filter_hover_svg from "../assets/img/icons/filter_hover.svg"
  import filter_selected_svg from "../assets/img/icons/filter_selected.svg"
  import FilterComponent from './FilterComponent.vue';
  import home_svg from "../assets/img/icons/home.svg"
  import home_hover_svg from "../assets/img/icons/home_hover.svg"
  import InfoPanel from './InfoPanel.vue';
  import layers_svg from "../assets/img/icons/layers.svg"
  import layers_hover_svg from "../assets/img/icons/layers_hover.svg"
  import layers_selected_svg from "../assets/img/icons/layers_selected.svg"
  import mapboxgl from 'mapbox-gl';
  import MapboxPlaces from './MapboxPlaces.vue';
  import MapPanel from './MapComponent.vue';
  import MunicipalSelect from './MunicipalSelect.vue';
  import ParcelSearch from './ParcelSearch.vue'
  import plus_logo_svg from "../assets/img/logos/plus.svg"                             
  import ReferenceOverlays from './ReferenceOverlays.vue'
  import search_svg from "../assets/img/icons/search.svg"
  import search_hover_svg from "../assets/img/icons/search_hover.svg"
  import search_selected_svg from "../assets/img/icons/search_selected.svg"
  import settings_svg from "../assets/img/icons/settings.svg"
  import settings_hover_svg from "../assets/img/icons/settings_hover.svg"
  import settings_selected_svg from "../assets/img/icons/settings_selected.svg"
  import toggle_off_svg from "../assets/img/icons/toggle_off.svg"
  import toggle_off_hover_svg from "../assets/img/icons/toggle_off_hover.svg"
  import toggle_on_svg from "../assets/img/icons/toggle_on.svg"
  import toggle_on_hover_svg from "../assets/img/icons/toggle_on_hover.svg"
  import toggle_png from "../assets/img/icons/arrow_left_2x.png"
  import up_svg from "../assets/img/icons/up_arrow.svg"

  export default {

    name: 'ExplorerComponent',

    components: {
        "blueprint-features": BlueprintFeatures,
        "blueprint-settings": BlueprintSettings,
        "bookmarks": BookmarkComponent,
        "component-overlays": ComponentOverlays,
        "info-panel": InfoPanel,
        "filter-panel": FilterComponent,
        "mapbox-places": MapboxPlaces,
        "map-panel": MapPanel,
        "muni-select": MunicipalSelect,
        "parcel-search": ParcelSearch,
        "reference-overlays": ReferenceOverlays,
    },

    /**
     * @description
     * @returns {any}
     */
    data() {
      return {
        all_layers_loaded: false,
        all_component_layers_loaded: false,
        all_reference_layers_loaded: false,
        back_svg,
        basemap_changed: false,
        blueprint_status: false,
        bookmarks_svg,
        bookmarks_hover_svg,
        bookmarks_selected_svg,
        break_points: {
          'xs':	0,
          'sm':	640,
          'md':	768,
          'lg':	1024,
          'xl':	1280,
          '2xl':	1536
        },
        cb_min: 0,
        blueprint_logo_png,
        change: false,
        symbology: {
          3: [
            [255, 255, 255, 0],
            [255, 255, 255, 0],
            [255, 255, 255, 0],
            [255, 255, 255, 0],
            [255, 255, 0],
            [255, 255, 0],
            [77, 230, 0],
            [77, 230, 0],
            [0, 112, 255],
            [0, 112, 255],
            [0, 112, 255]
          ],
          4: [
            [255, 255, 190],
            [255, 255, 190],
            [161, 250, 180],
            [161, 250, 180],
            [65, 183, 196],
            [65, 183, 196],
            [44, 127, 184],
            [44, 127, 184],
            [120, 39, 143],
            [120, 39, 143],
            [120, 39, 143]
          ],
          5: [
            [239, 243, 255],
            [239, 243, 255],
            [189, 215, 231],
            [189, 215, 231],
            [107, 174, 214],
            [107, 174, 214],
            [49, 130, 189],
            [49, 130, 189],
            [13, 66, 179],
            [13, 66, 179],
            [13, 66, 179]
          ],
          7: [
            [247, 252, 245],
            [247, 252, 245],
            [199, 233, 192],
            [199, 233, 192],
            [116, 196, 118],
            [116, 196, 118],
            [35, 139, 69],
            [35, 139, 69],
            [6, 82, 32],
            [6, 82, 32],
            [6, 82, 32]
          ],
        8:['#0070FF',
           '#0070FF',
           '#0070FF',
           '#0070FF',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#FFFF00',
           '#FFFF00'
          ],
        9:['#FFFFBE',
           '#FFFFBE',
           '#A1FAB4',
           '#A1FAB4',
           '#41B7C4',
           '#41B7C4',
           '#2C7FB8',
           '#2C7FB8',
           '#78278F',
           '#78278F',
           '#78278F'
          ],

        10:['#0070FF',
           '#0070FF',
           '#0070FF',
           '#0070FF',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#4DE600',
           '#FFFF00',
           '#FFFF00'
          ]
        },
        current_feature: {},
        default_panel: 'overlays',
        data_panels:{
          0: 'search',
          1: 'filters',
          2: 'overlays',
          3: 'settings',
          4: 'bookmarks',
          5: 'info-panel'
        },
        feature_opacity: .8,
        default_style: 'mapbox://styles/mapbox/light-v10',
        default_view: {
            'lat': 40.0790,
            'lng': -75.8510,
            'lat_small_screen': 40.265134263012186,
            'lng_small_screen': -74.56625054468779,
            'zoom': 7.26,
            'bearing': 0,
            'pitch': 10
        },
        down_svg,
        feature_svg,
        feature_id: '0',
        feature_hover_svg,
        feature_types: {0: 'place',
                        1: 'municipality',
                        2: 'block',
                        3: 'parcel'},
        show_features: true,
        feature_selected_svg,
        features: null,
        filters: null,
        filter_count: 0,
        filter_hover_svg,
        filter_selected_svg,
        filter_svg,
        graph_data: null,
        has_legend: false,
        home_svg,
        home_hover_svg,
        hovering_bm: false,
        hovering_ip: false,
        hovering_options:false,
        info_marker: null,
        info_marker_coords: null,
        info_panel: true,
        info_popup: null,
        label_layer_id: null,
        layers_svg,
        layers_hover_svg,
        layers_selected_svg,
        loading: false,
        blueprint_logo_footer_svg,
        map_bounds: null,
        map_loaded: false,
        map_opacity: 1,
        map: null,
        mapbox_token: 'pk.eyJ1IjoibWNvcmJhbGlzIiwiYSI6ImNpc2I1dnczaD'
                    + 'AwMTgyb3FvbTFyZ3BuY3QifQ.HOUyxTBQQDeZa748snMQzw',
        menu_selection: -1,
        modiv_status: false,
        municipality: null,
        openspace_status: false,
        overlay_popup: null,
        overlays: [],
        panel_height: 0,
        panel_open: false,
        parcel_click: true,
        parcel_on_click: true,
        parcel_size_min: 0,
        plus_logo_svg,
        query_string: '',
        resize_ob: null,
        restore_feature_data: null,
        saved_features: {},
        saved_feature_count: 0,
        search_svg,
        search_hover_svg,
        search_selected_svg,
        selected: 4,
        selection_codes: {
          3: ["Agriculture", "ag", '2023'],
          4: ["Ecological", "eco", '2020'],
          5: ["Water", "wa", '2020'],
          'ag': 3,
          'eco': 4,
          'wa': 5,
        },
        settings_svg,
        settings_hover_svg,
        settings_selected_svg,
        show_component_overlays: true,
        show_content: true,
        show_sidebar_prompt: false,
        show_backlink: false,
        state_to_restore: {},
        style_change: false,
        toggle_on_svg,
        toggle_on_hover_svg,
        toggle_off_svg,
        toggle_off_hover_svg,
        toggle_png,
        up_svg,
        update_legend: false,
        y_start: 0,
        zoom: 8,
        zoom_threshold_met: false,
        x: 0
      };
    },

    computed: {

      /**
       * Computed variable.  Returns appropriate height for info panel on small
       * screens.
       * @returns {number}
       */
      content_height: function(){
        const pct = this.small_screen?.8:1;
   
        return parseInt(
                document.getElementById('map-container').offsetHeight * pct
              );
      },

      /**
       * Computed variable.  Returns apppropriate class for the content panel
       * based on window size and user inputs
       * @returns {string}
       */
       content_width_class: function(){
        if(this.small_screen){
          return 'w-full';
        }else if(!this.show_content){
          return 'w-0';
        }else if(window.innerWidth < this.break_points['lg']){
          return 'w-6/12';
        }else{
          return 'w-1/3';
        }
      },

      /**
       * Computed variable.  Returns apppropriate class for the menu
       * based on window size and user inputs
       * @returns {string}
       */
       menu_width_class: function(){
        if(this.small_screen){
          return 'w-full';
        }else if(!this.show_content){
          return 'w-0';
        }else if(window.innerWidth < this.break_points['lg']){
          return 'w-6/12';
        }else{
          return 'w-1/3';
        }
      },

      /**
       * Computed variable.  Returns apppropriate class for the title element
       * based on window size and user inputs
       * @returns {string}
       */
      title_width_class: function(){
        if(window.innerWidth < this.break_points['md']){
          return 'w-0';
        }else{
          return 'w-1/3';
        }
      },

      /**
       * Computed variable.  Count of open overlays.
       * @returns {number}
       */
       overlay_count: function(){
        return this.overlays.length;
      },

      /**
       * Computed variable.  Returns screen width.
       * @returns {number}
       */
      screen_width: function(){
        return String(window.screen.width);
      },

      /**
       * Computed variable.  Array of saved (bookmarked) features.
       * @returns {string[]}
       */
      saved_feature_ids: function(){
        return Object.keys(this.saved_features).join();
      },

      /**
       * Computed variable.  Object of bookmarked parcels with pams_pin as key.
       * @returns {Object}
       */
      saved_parcels: function(){
        let obj = {};
        const keys = Object.keys(this.saved_features);
        keys.forEach((key, index) => {
          if(key.split('_').length > 1){
            obj[key] = this.saved_features[key]
          }
        });
        return obj;
      },

      /**
       * Computed variable.  True if the conditions needed to show the feature
       * info panel have been met.
       * @returns {boolean}
       */
      show_info_panel: function () {
        let show = (this.feature_id != '0' &&
                  (this.modiv_status ||
                    this.blueprint_status)) == true;

        return show;
      },

      /**
       * Computed variable.  True if screen width is less than medium breakpont.
       * @returns {boolean}
       */
      small_screen: function(){
         return window.innerWidth < this.break_points['md'];
      },

      /**
       * Computed variable.  Returns inner height of the window.  Used for 
       * positioning and sizing of other elements.
       * @returns {number}
       */
      window_height: function(){
        return window.innerHeight;
      }
    },

    watch:{
      /**
       * Watched variable function. Initiates process for showing/hiding feature
       * info panel.
       */
      show_info_panel: function(){        
        this.updateInfoPanelStatus()
      },

      /**
       * Watched variable function. Updates DOM on feature change.
       */
      feature_id: function(){   
        if(this.feature_id != '0'){
          this.toggleContentVisibility(true);
          this.changeSelection({'data-idx':this.getMenuIndex('info-panel')});
        }   
      }
    },

    /**
     * Vue lifecycle function.  Initiates processing of URL query string.
     * Creates resizable panel for small screens.
     */
    mounted(){
      history.pushState({}, '', document.location.origin);
      this.processQueryString();
      this.updateInfoPanelStatus();
    },

    methods:{

      /**
       * Adds a geoJSON overlay of a municipal boundary to the map and zooms
       * the map to its bounding box.
       * @param {string} muni Four-digit municipality code.
       */
      addMunicipality(muni){
        this.municipality = muni;

        if(!this.municipality){
          if (this.map.getLayer('municipality')) {
            this.map.removeLayer('municipality');
            this.map.getSource('muni').setData({});
          }
          return;
        }

        if (this.map.getLayer('municipality')) {
          this.map.removeLayer('municipality');
          this.map.getSource('muni').setData(muni.geojson);
        }else if(this.map.getSource('muni')){
          this.map.getSource('muni').setData(muni.geojson);
        }else{
          this.map.addSource('muni', {
          'type': 'geojson',
          'data': muni.geojson
          });
        }

        this.map.addLayer({
          'id': 'municipality',
          'type': 'line',
          'source': 'muni',
          'layout': {
          'line-join': 'round',
          'line-cap': 'round'
          },
          'paint': {
          'line-color': '#FF00FF',
          'line-width': 6
          }
        },  this.label_layer_id);

        const coordinates = muni.geojson.features[0].geometry.coordinates;
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0][0],
          coordinates[0][0]
        );

        for (const coord of coordinates[0]) {
          bounds.extend(coord);
        }

        this.map.fitBounds(bounds, {
          padding: 20
        });

      },

      /**
       * Handles menu clicks by displaying/hiding content panels
       * @param {Object} obj Click event or object with a "data-idx" key
       * @param {boolean} show_target = false  If true, info-panel is shown on 
       * small screens.
       */
      changeSelection(obj, show_target = false){
        //idx depends on whether source is menu click or info-panel:
        let idx = null;
        let menu_click = false;
        const els = document.getElementsByClassName("content-block");
        const container = document.getElementById('content-container');

        if(obj.hasOwnProperty('data-idx')){
          idx = obj['data-idx'];
        }else{
          idx = obj.currentTarget.dataset.idx;
        }

        if(this.small_screen){
          const height = String(this.content_height) + 'px';
          if(this.menu_selection == idx  && this.panel_open){
            this.closePanel();
            return;
          }else if(!this.panel_open){
            container.style.height = height;
            this.panel_height = this.content_height;
            this.panel_open = true;
          }
        }else{
          container.style.height = String(this.content_height) + 'px';
          this.panel_height = this.content_height;
          this.panel_open = true;
        }

        for(const el of els){
            if(el.dataset.idx == idx){
              if(this.panel_open){
                el.classList.remove('hidden');
              } 
            }else{
              el.classList.add('hidden');
            }
        }

        this.menu_selection = idx;
      },

      closePanel(){

        if(!this.small_screen){
          return;
        }

        const els = document.getElementsByClassName("content-block");

        for(const el of els){
          el.classList.add('hidden');
        } 

        document.getElementById('content-container').style.height = '0px';
        this.panel_open = false;
      },

      /**
       * Adds a marker to map and zooms the map to it.
       * @param {Object} obj {"source": <string>, "id": <string>, "coordinate": 
       * <point coordinates>, "data": <MVT tile feature click event object>
       */
      dropMarker(obj){
          if(!obj.hasOwnProperty('source')){
            return;
          }

          const source = obj.source;
          let class_name = 'feature';
          let coordinates = [];
          let id = null;
          let title = null;
 
          this.updateBlueprintDataStatus(false);

          switch(source){
            case 'parcel_search':
              coordinates = obj.data.coordinates;
              id = obj.data.feature_id;
              title = id;
              break;

            case 'map_click':
              title = '';
              id = '-1';

              if(obj.hasOwnProperty('data')){
                if(obj.data.hasOwnProperty('lngLat')){
                  if(obj.data.lngLat){
                    coordinates = [obj.data.lngLat.lng, obj.data.lngLat.lat];
                  }else{
                    coordinates = [0, 0];
                  }
                }
              }
              break;

            case 'bookmark':
              coordinates = obj.data.coordinates;
              id = obj.data.feature_id;
              title = id;
              break;

            case 'query_string':
              coordinates = obj.coordinates;
              id = obj.feature_id;
              title = id;
              break;
          }

          if(class_name == 'feature'){
              this.removeInfoMarker();
              this.updateInfoMarkerCoordinates(coordinates);
              this.updateFeatureId(id);
          }

          const html =   `<div class="pt-5 pb-3 px-3
                             rounded-lg text-sm bg-white bg-opacity-80 h-auto
                             text-gray-800">
                             ${title}
                          </div>`;

          const popup = new mapboxgl.Popup({
              anchor: 'top',
              className: class_name,
              offset: { 'bottom': [0, -200] },
              closeOnClick: false,
              maxWidth: 'none'
          }).setHTML(html);

          const marker = new mapboxgl.Marker({clickTolerance: 5})
              .setLngLat(coordinates)
              .addTo(this.map);

          this.info_marker = marker;
          this.info_popup = popup;

          let z = 14;

          if(this.small_screen){
            this.map.flyTo({
              center: coordinates,
              zoom: z,
              speed: 1.7
            });
          }else{
            this.map.setZoom(z);
  
            const bounds = this.map.getBounds().toArray();
            const lng_diff = (bounds[0][0] - bounds[1][0])/-4;

            const map_center = [...coordinates];

            map_center[0] -= lng_diff;
            this.map.panTo(map_center,{duration: 1000});
          }
      },

      getImage(){
          var dataURL = this.map.getCanvas().toDataURL()
          var a = document.createElement('a');
          a.href = dataURL;
          a.download = 'test.png';
          var l = document.getElementById("imageLink");
          l.appendChild(a);
          a.click();
      },

      /**
       * Gets a content panels index number from its name
       * @param {string} name
       * @returns {integer}
       */
      getMenuIndex(name){
        return Object.keys(this.data_panels).map(Number)
                            .find(key => this.data_panels[key] ===name);
      },

      /**
       * Toggles component's "basemap_changed" variable;
       * @returns {void}
       */
      handleBasemapChange(){
        this.basemap_changed = !this.basemap_changed;
      },

      /**
       * Opens a parcel report in a new tab/window for printing.
       * @param {Object} data Feature object
       * key that references a bookmarked featureXXX.
       */
      printFeature(data){
        if(!data){
          return;
        }

        let feature_data = {};

        if(Object.keys(data).length == 1){
          if(data.hasOwnProperty('feature_id')){
            feature_data = this.saved_features[data.feature_id];
          }else{
            return;
          }
        }else{
         feature_data = data;
        }
        
        const routeData = this.$router.resolve({
          name: 'ParcelReport', query: {data: JSON.stringify(feature_data)}});
        window.open(routeData.href, '_blank');
        this.pushToAnalytics('opened_report', 
                              {'type':'single','pins': data.feature_id});
      },

      /**
       * Opens a multi-parcel report in a new tab/window for printing
       * a report on bookmarked parcels.
       */
      printMultiParcelReport(){

        const routeData = this.$router.resolve({
          name: 'MultiParcelReport', 
          query: {data: JSON.stringify(this.saved_parcels)}});
        window.open(routeData.href, '_blank');
        
        this.pushToAnalytics('opened_report', 
                      {'type':'multi','pins': Object.keys(this.saved_parcels)});
      },

      /**
       * Populates the 'state_to_restore' object, a property of the component
       * containing all map elements to be restored.  The object 
       * is created from query string parameters and is used to recreate a map 
       * from a shared link.  
       */
      processQueryString(){
        const params = this.$route.query;

        if(!params){
          return;
        }
        let restorables = {
            'feature_id': params['f']?params['f']:this.feature_id,
            'show_features':  
                    params['fi']?params['fi']:this.show_features,
            'filters': params['fl']?
                    params['fl'].split(',').map(e=>JSON.parse(e)):this.filters,
            'info_panel': params['ip']?params['ip']:this.info_panel,
            'opacity': parseFloat(params['op']?params['op']:
                                                          this.feature_opacity),
            'openspace_status': params['o']?params['o']=='t'?true:false:
                                                          this.openspace_status,
            'overlays': params['ols']?params['ols'].split(','):
                                                                  this.overlays,
            'parcel_click': params['pk']?params['pk']:this.parcel_click,
            'pin_coordinates': params['pc']?params['pc']:null,
            'saved':  params['fs']?params['fs'].split(','):
                                                            this.saved_features,
            'selected':  params['s']?params['s']:this.selected,
            'style':  params['sty']?params['sty']:null,
            'view':{
                'zoom': params['z']?params['z']:this.default_view.zoom,
                'lat':  params['la']?params['la']:this.default_view.lat,
                'lat_small_screen': this.default_view.lat_small_screen,
                'lng':  params['ln']?params['ln']:this.default_view.lng,
                'lng_small_screen': this.default_view.lng_small_screen,
                'bearing':  params['b']?params['b']:this.default_view.bearing,
                'pitch':  params['p']?params['p']:this.default_view.pitch
            }
        };

        if(![3,4,5].includes(parseInt(restorables.selected))){
          restorables.selected = 4;
        }

        if(restorables.pin_coordinates &&
           restorables.feature_id){

          const obj = {
            "stamp":  Date.now(),
            "coordinates": restorables.pin_coordinates
                           .split(',')
                           .map(Number),
            "feature_id": restorables.feature_id,
            "source": "query_string"
          }

          this.dropMarker(obj);
        }

        this.setMapStyle(restorables.style);

        if(restorables.filters){
          this.updateFilterCount(restorables.filters.filter(e => e).length);
        }

        if(restorables.selected != this.selected){
          this.updateModelSelection(
                                  {'selected': restorables.selected});
        }

        if(restorables.saved.length > 0){
          restorables.saved.forEach((value) =>
            {
              this.restoreBookmark(value);
            }
          );
        }

        if(restorables.overlays.length > 0){
          this.updateOverlays(restorables.overlays);
        }

        this.updateFeatureOpacity(restorables.opacity);
        // this.enableFeatues(restorables.show_features);
        // this.updateOpenspaceStatus(restorables.openspace_status);
        // this.updateParcelClick(restorables.parcel_click);
        this.state_to_restore = restorables;
      },


      /**
       * Adds event to Google Analytics dataLayer.
       */
      pushToAnalytics(type, params){
        dataLayer.push({
          'event': type,
          ...params
        });
      },

      /**
       * Removes feature marker and associated popups, if they exist, from map.
       */
      removeInfoMarker(){
        if(this.info_marker){
          this.info_marker.remove();
          this.info_marker = null;
        }
        if(this.info_popup){
          this.info_popup.remove();
          this.info_popup = null;
        }
        if(this.overlay_popup){
          document.getElementById('parcel-popup_info').remove();
          // this.overlay_popup.remove();
          // this.overlay_popup = null;
        }
      },

      /**
       * "Unselects" a feature and resets variables associated with a selected
       * feature to default values.
       */
      removeInfoPanel(){
        this.updateBlueprintDataStatus(false);
        this.updateFeatureId('0');
        this.updateInfoMarkerCoordinates();
        this.removeInfoMarker();
        this.changeSelection({'data-idx': this.getMenuIndex('info-panel')})
      },

      /**
       * Restores a bookmark for a feature.  Used as part of the process for 
       * restoring map state from a shared link.
       * @param {string} feature_id
       */
      restoreBookmark(feature_id){
        const fg = new FeatureGetter();
        const request_obj = {'feature_id': feature_id,
                             'centroid': true,
                             'coords': null};

        fg.getFeature(request_obj)
          .then(response=>{this.saveFeature(response)})
          .catch((e)=>{ return; });
      },

      /**
       * Selects a feature.  Used to set a bookmarked feature as the selected 
       * feature or to restore a selected feature when restoring map state from 
       * a shared link.wedewrwe
       * @param {string} feature_id
       */
      restoreFeature(feature_id){
        
        return new Promise((resolve, reject) => {
        
            if (!feature_id){ 
              reject( false ); 
            }

          const stamp = Date.now();
          const obj = {
            "data": this.saved_features[String(feature_id)],
            "source": "bookmark"
          }

          this.setRestoreFeatureData(obj.data);
          this.dropMarker(obj);
          this.updateCurrentFeature(this.saved_features[String(feature_id)]);

          resolve(true);
        });
      },

      /**
       * Bookmarks a selected feature.
       * @param {Object} data  A feature object.
       */
      saveFeature(data){
        const feature_id = data.feature_id;
        const saved = this.saved_features.hasOwnProperty(feature_id)?true:false;

        if(!saved){
          this.saved_features[String(feature_id)] = data;
          this.saved_feature_count++;
          this.pushToAnalytics('saved_parcel', {'pams_pin': feature_id});
        }
      },

      /**
       * Sets the map bounds to the passed values.
       * @param {Object} bounds Mapbox like lat/lng object.
       */
      setBounds(bounds){
        this.map_bounds = bounds;
      },

      /**
       * Sets the "label_layer_id" variable that identifies the label layer of 
       * the basemap.  Used to place overlays beneath map labels..
       * @param {any} layer
       */
      setLabelLayerID(layer){
        this.label_layer_id = layer;
      },

      /**
       * Sets the Vue component "map" variable to the provided Mapbox map 
       * object.
       * @param {Object} map  Mapbox map.
       */
      setMap(map){
        this.map = map;
      },

      /**
       * Sets the active background map to the passed Mapbox style.  Used when 
       * restoring map state from a shared link.
       * @param {string} style
       */
      setMapStyle(style){
        if(style){
          this.map.setStyle('mapbox://styles/mapbox/' + style);
        }
      },

      /**
       * Sets the Vue component "parcel_size_min" variable to the provided 
       * value. Features with an area (acs.) smaller than the provided value
       * are not displayed.
       * @param {integer} val
       */
      setParcelSizeMinimun(val){
        this.parcel_size_min = val?val:this.parcel_size_min;
      },

      /**
       * Sets the Vue component "restore_feature_data" variable to the provided 
       * value. Used when setting a bookmarked feature as the selected 
       * feature or when restoring map state from a shared link
       * @param {Object} data Feature object.
       */
      setRestoreFeatureData(data){
        this.restore_feature_data = data;
        this.updateBlueprintDataStatus(true);
      },

      /**
       * Sets the Vue component "setShowBacklink" variable to the provided 
       * boolean.  
       * @param {any} status = true
       */
      setShowBacklink(status = true){
        this.show_backlink = status;
      },

      /**
       * Sets the Vue component "show_features" variable to the provided 
       * boolean. When true, Blueprint overlays are visible and clickable. 
       * @param {boolean} status
       */
      enableFeatues(status){
        this.show_features = status;
        this.pushToAnalytics('settings_change', 
            {'setting': 'show features', 'setting_value': status });
      },

      showLegend(){
          this.has_legend = true;
      },

      /**
       * Sets an image tags src attribute to the provided source.
       * @param {Object} element HTML Image element
       * @param {string} src
       */
      swapImage(element,src){
        if(element.dataset.idx == this.menu_selection){
          return;
        }
        element.src = src;
      },

      /**
       * Toggles the visibility of the left panel on larger displays.
       */
      toggleContentVisibility(visible){

        if(visible){
          this.show_content = true;
        }else{
          this.show_content = !this.show_content;
        }
      },

      /**
       * Rmoves (unbookmarks) a bookmarked feature.
       * @param {Object} data  Feature object.
       */
      unsaveFeature(data){
        const feature_id = data.feature_id;
        const saved = this.saved_features.hasOwnProperty(feature_id)?true:false;

        if(saved){
          delete this.saved_features[String(feature_id)];
          this.saved_feature_count--;
        }
        // if(this.saved_feature_count == 0){
        //   if(this.show_info_panel){
        //     this.changeSelection({'data-idx':
        //                                   this.getMenuIndex('info-panel')})
        //   }else{
        //     this.changeSelection({'data-idx':
        //                                   this.getMenuIndex('overlays')})
        //   }
        // }
      },

      /**
       * Sets the Vue component "all_layers_loaded" variable to true when 
       * reference and component layers are loaded
       */
      updateAllLayersLoaded(src){
        if(src == 'reference'){
          this.all_reference_layers_loaded = true;
        }else{
          this.all_component_layers_loaded = true
        }
        
        if(this.all_component_layers_loaded & this.all_reference_layers_loaded){
          this.all_layers_loaded = true;
        }
      },

      /**
       * Sets the Vue component "blueprint_status" variable to the provided 
       * boolean.  True when Blueprint data for a selected feature has been 
       * retrieved from the database.
       * @param {boolean} status
       */
      updateBlueprintDataStatus(status){
           this.blueprint_status = status;
      },

      /**
       * Delays call to updateBlueprintDataStatus() to allow time for reative
       * data to render in the Info panel .
       * @param {boolean} status
       */
       updateBlueprintDataStatusWithDelay(status){
         setTimeout(() => {
           this.updateBlueprintDataStatus(status);
         }, 2000);
      },
      /**
       * Sets the Vue component "current_feature" variable to the provided 
       * feature object.
       * @param {Object} feature  Feature object.
       */
      updateCurrentFeature(feature){
        if(feature){
          this.current_feature = feature;
          if(Object.keys(this.current_feature).length != 0){
            if(this.current_feature.hasOwnProperty('feature_id')){
              this.updateFeatureId(this.current_feature.feature_id);
            }
          }
        }else{
          this.current_feature = {};
        }
        this.updateMarkerPosition();
        this.updateMarkerHTML();
      },

      /**
       * Sets the Vue component "feature_id" variable to the provided ID.  This
       * is the currently selected feature.
       * @param {string} id
       */
      updateFeatureId(id){
        this.feature_id = id;
      },

      updateFeatureOpacity(opacity){
        this.feature_opacity = opacity;
      },

      updateFeatures(features){
        this.features = features;
      },

      updateFilterCount(ct){
        this.filter_count = ct;
      },
      
      updateFilters(filters){
        this.filters = filters;
      },

      /**
       * Sets the Vue component "info_marker_coords" variable to the 
       * coordinates provided.  
       * @param {Object} coordinates Mapbox like lat/lng object.
       */
      updateInfoMarkerCoordinates(coordinates){
          this.info_marker_coords = coordinates;
      },

      /**
       * Changes the displayed content panel based on the value of the 
       * "show_info_panel" variable and the screen size.
       */
      updateInfoPanelStatus: function(){
        if(this.show_info_panel){
          this.changeSelection({'data-idx': this.getMenuIndex('info-panel')});
        }else{
          if(!this.small_screen){
            this.changeSelection(
                        {'data-idx': this.getMenuIndex(this.default_panel)});
          }
        }
      },

      updateLegend(){
        this.update_legend = !this.update_legend;
      },

      /**
       * Toggles the opacity of the map panel.  When true opacity is reduced.
       * When false opacity is 100%.
       * @param {any} loading
       */
      updateLoading(loading){
        this.loading = loading;
        this.map_opacity = loading?.2:1;
      },

      /**
       * Sets the Vue component "map_loaded" variable to true.  Called when
       * the map is fully instantiated.
       */
      updateMapLoaded(){
        this.map_loaded = true;
      },

      /**
       * Updates the content of the popup associated with a feature marker
       * with data retrieved from the database for the selected feature.
       */
      updateMarkerHTML(){
        let html = document.createElement('div');
        let muni_div;
        let parcel_div;
        let block_label;
        let block_name;
        let lot_label;
        let lot_name;

        html.setAttribute('id','parcel-popup_info');
        html.classList.add('p-3');
        html.classList.add('-mx-2');
        html.classList.add('-mt-4');
        html.classList.add('rounded');

        muni_div = document.createElement('div');
        muni_div.classList.add('font-semibold')
        muni_div.classList.add('mb-0.5')
        muni_div.appendChild(
            document.createTextNode(this.current_feature.response_data.muni));
        html.appendChild(muni_div);
        parcel_div = document.createElement('div');
        parcel_div.classList.add('text-center');
        block_label = document.createElement('span');
        block_name = document.createElement('span');

        block_label.classList.add('text-gray-500');
        block_label.appendChild(document.createTextNode('B: '));
        parcel_div.appendChild(block_label);
        block_name.appendChild(
                        document.createTextNode(this.current_feature.block));
        parcel_div.appendChild(block_name);

        lot_label = document.createElement('span');
        lot_name = document.createElement('span');

        lot_label.classList.add('text-gray-500');
        lot_label.classList.add('pl-2');
        lot_label.appendChild(document.createTextNode(' L: '));
        parcel_div.appendChild(lot_label);
        lot_name.appendChild(
                          document.createTextNode(this.current_feature.lot));
        parcel_div.appendChild(lot_name);

        if(this.current_feature.qualifier){
          let qual_label = document.createElement('span');
          let qual_name = document.createElement('span');

          qual_label.classList.add('text-gray-500');
          qual_label.classList.add('pl-2');
          qual_label.appendChild(document.createTextNode(' Q: '));
          parcel_div.appendChild(qual_label);
          qual_name.appendChild(
                    document.createTextNode(this.current_feature.qualifier));
          parcel_div.appendChild(qual_name);
        }

        parcel_div.appendChild(document.createElement('hr'));
        html.appendChild(parcel_div);
          
        if(this.overlay_popup){
          let target = this.overlay_popup.getElement()
                           .querySelector(".mapboxgl-popup-content");

          html.classList.add('mb-5');
          target.prepend(html);
        }else{
          html.classList.add('mb-2');
          this.info_popup.setDOMContent(html);
          this.info_marker.setPopup(this.info_popup);
          this.info_marker.togglePopup();
        }
      },

      updateMarkerPosition(){
        // relocate to feature centroid
        if(this.current_feature.coordinates){
          this.info_marker.setLngLat(this.current_feature.coordinates);
        }
      },

      /**
       * Sets the Vue component "selected" variabe value to the provided model.
       * @param {Object} obj HTML select input (model name options) onchange 
       * object.
       */
      updateModelSelection(obj){
        if(obj.target){
          this.selected  = obj.target.value;
        }else if(obj.hasOwnProperty('selected')){
          this.selected  = obj.selected
        }
      },

      /**
       * Sets the Vue component "modiv_status" value to the provided boolean.
       * True when MODIV data has been retrieved from the database for the 
       * selected feature.
       * @param {boolean} status
       */
      updateModivDataStatus(status){
        this.modiv_status = status;
      },

      /**
       * Toggles Vue component "openspace_status" value;
       * @param {boolean} status.
       */
       updateOpenspaceStatus(status){
        this.openspace_status = status;
        this.pushToAnalytics('settings_change', 
            {'setting': 'show openspace', 'setting_value': status });
      },

      /**
       * Updates content and location of the popup associated with non-Blueprint
       * overlays.
       * @param {Object} popup {'html': html, 'coords': e.lngLat}
       */
      updateOverlayPopup(popup){
        this.overlay_popup = popup;
      },

      /**
       * Sets the Vue component "overlays" array to the provided array of 
       * overlay layer ids.
       * @param {array} overlays Overlay ids.
       */
      updateOverlays(overlays){
        this.overlays = overlays;
      },

      /**
       * Sets the component "parcel_click" value
       * @param {boolean} status;
       */
      updateParcelClick(status){
        this.parcel_click = status;
        this.parcel_on_click = status;
        this.pushToAnalytics('settings_change', 
            {'setting': 'parcel_click', 'setting_value': status });
      },
      
      /**
       * Sets the Vue component "share_link" variable to the provided url
       * @param {string} url
       */
      updateShareLink(url){
        this.share_link = url;
      },

      /**
       * Toggles the Vue component "style_change" boolean variable.  Used to 
       * trigger actions needed when the basemap is changed.
       */
      updateStyleChange(){
        this.style_change = !this.style_change;
      },

      /**
       * Sets the Vue component "zoom" variable to the provided value.  Used to 
       * set the map zoom level.
       * @param {number} zoom
       */
      updateZoom(zoom){
        this.zoom = zoom;
      },

      /**
       * Sets the Vue component "zoom_threshold_met" variable to the provided 
       * value.  Used to set the type of feature to display.
       * @param {boolean} threshold_met
       */
      updateZoomThresholdMet(threshold_met){
        this.zoom_threshold_met = threshold_met;
      },
    }
  }

</script>

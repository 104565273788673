<template>

  <div class="h-auto w-full text-base md:text-lg xl:text-xl 2xl:text-3xl 
            text-gray-400 pl-2 pt-1 mb-8 mr-4 overflow-auto font-Open+Sans">

      <div class="grid grid-cols-7 mt-10">

          <!-- START FIRST SETTING -->
          <div class="flex col-span-6 col-start-2">
              <div class="flex-none">
                  <input
                      class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] 
                            before:pointer-events-none before:absolute 
                            before:h-3.5 before:w-3.5 before:rounded-full 
                            before:bg-transparent before:content-[''] after:absolute 
                            after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 
                            after:rounded-full after:border-none after:bg-neutral-100 
                            after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)]
                            after:transition-[background-color_0.2s,transform_0.2s] 
                            after:content-[''] checked:bg-primary checked:after:absolute 
                            checked:after:z-[2] checked:after:-mt-[3px] 
                            checked:after:ml-[1.0625rem] checked:after:h-5 
                            checked:after:w-5 checked:after:rounded-full 
                            checked:after:border-none checked:after:bg-primary 
                            checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
                            checked:after:transition-[background-color_0.2s,transform_0.2s] 
                            checked:after:content-[''] hover:cursor-pointer 
                            focus:outline-none focus:ring-0 focus:before:scale-100 
                            focus:before:opacity-[0.12] 
                            focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
                            focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            focus:after:absolute focus:after:z-[1] focus:after:block 
                            focus:after:h-5 focus:after:w-5 focus:after:rounded-full 
                            ocus:after:content-[''] checked:focus:border-primary 
                            checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] 
                            checked:focus:before:scale-100 
                            checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
                            checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            dark:bg-neutral-600 dark:after:bg-neutral-400 
                            dark:checked:bg-primary dark:checked:after:bg-primary 
                            dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                            dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      :class="{'bg-green-400':model_status,
                              'bg-red-400':!model_status}"
                      type="checkbox"
                      role="switch"
                      @change="updateModelStatus"
                      v-model="model_status"
                      id="setting_model-toggle" 
                  />
              </div>
              <div class="flex-1">
                  <label
                      class="inline-block hover:cursor-pointer text-sm md:text-base 
                            xl:text-xl 2xl:text-2xl text-gray-500 text-left pt-2 pl-2"
                      for="model-toggle"
                      @click="toggleModelStatus"
                      >Show Blueprint models on the map
                  </label>
              </div>
          </div>

          <!-- START SECOND SETTING -->
          <div class="flex col-span-6 col-start-2">
              <div class="flex-none">
                  <input
                      class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] 
                            before:pointer-events-none before:absolute 
                            before:h-3.5 before:w-3.5 before:rounded-full 
                            before:bg-transparent before:content-[''] after:absolute 
                            after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 
                            after:rounded-full after:border-none after:bg-neutral-100 
                            after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)]
                            after:transition-[background-color_0.2s,transform_0.2s] 
                            after:content-[''] checked:bg-primary checked:after:absolute 
                            checked:after:z-[2] checked:after:-mt-[3px] 
                            checked:after:ml-[1.0625rem] checked:after:h-5 
                            checked:after:w-5 checked:after:rounded-full 
                            checked:after:border-none checked:after:bg-primary 
                            checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
                            checked:after:transition-[background-color_0.2s,transform_0.2s] 
                            checked:after:content-[''] hover:cursor-pointer 
                            focus:outline-none focus:ring-0 focus:before:scale-100 
                            focus:before:opacity-[0.12] 
                            focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
                            focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            focus:after:absolute focus:after:z-[1] focus:after:block 
                            focus:after:h-5 focus:after:w-5 focus:after:rounded-full 
                            ocus:after:content-[''] checked:focus:border-primary 
                            checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] 
                            checked:focus:before:scale-100 
                            checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
                            checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            dark:bg-neutral-600 dark:after:bg-neutral-400 
                            dark:checked:bg-primary dark:checked:after:bg-primary 
                            dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                            dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      :class="{'bg-green-400':openspace_status,
                              'bg-red-400':!openspace_status}"
                      type="checkbox"
                      role="switch"
                      @change="updateOpenspaceStatus"
                      v-model="openspace_status"
                      id="setting_openspace-toggle" 
                  />
              </div>
              <div class="flex-1">
                  <label
                      class="inline-block hover:cursor-pointer text-sm xs:text-xs md:text-base 
                            xl:text-xl 2xl:text-2xl text-gray-500 text-left pt-2 pl-2"
                      for="openspace-toggle"
                      @click="toggleOpenspacetatus"
                      >Show Blueprint models for preserved parcels
                  </label>
              </div>
          </div>

          <!-- START THIRD SETTING -->
          <div class="flex col-span-6 col-start-2">
              <div class="flex-none">
                  <input
                      class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] 
                            before:pointer-events-none before:absolute 
                            before:h-3.5 before:w-3.5 before:rounded-full 
                            before:bg-transparent before:content-[''] after:absolute 
                            after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 
                            after:rounded-full after:border-none after:bg-neutral-100 
                            after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)]
                            after:transition-[background-color_0.2s,transform_0.2s] 
                            after:content-[''] checked:bg-primary checked:after:absolute 
                            checked:after:z-[2] checked:after:-mt-[3px] 
                            checked:after:ml-[1.0625rem] checked:after:h-5 
                            checked:after:w-5 checked:after:rounded-full 
                            checked:after:border-none checked:after:bg-primary 
                            checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] 
                            checked:after:transition-[background-color_0.2s,transform_0.2s] 
                            checked:after:content-[''] hover:cursor-pointer 
                            focus:outline-none focus:ring-0 focus:before:scale-100 
                            focus:before:opacity-[0.12] 
                            focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] 
                            focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            focus:after:absolute focus:after:z-[1] focus:after:block 
                            focus:after:h-5 focus:after:w-5 focus:after:rounded-full 
                            ocus:after:content-[''] checked:focus:border-primary 
                            checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] 
                            checked:focus:before:scale-100 
                            checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] 
                            checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                            dark:bg-neutral-600 dark:after:bg-neutral-400 
                            dark:checked:bg-primary dark:checked:after:bg-primary 
                            dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                            dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      :class="{'bg-green-400':parcel_click,
                              'bg-red-400':!parcel_click}"
                      type="checkbox"
                      role="switch"
                      @change="updateParcelClick"
                      v-model="parcel_click"
                      id="setting_parcel-click" 
                  />
              </div>
              <div class="flex-1">
                  <label
                      class="inline-block hover:cursor-pointer text-sm xs:text-xs md:text-base 
                            xl:text-xl 2xl:text-2xl text-gray-500 text-left pt-2 pl-2"
                      for="parcel-click"
                      @click="toggleParcelStatus"
                      >Map Click Selects New Parcel
                  </label>
              </div>
          </div>
      </div>
      <!-- END SETTINGS -->

      <div class="pt-4 bg-white text-center">
          <span class="text-base text-cbp opacity-20">
              engage. protect. restore.
          </span>
          <img :src="blueprint_logo_footer_svg"
              alt="Conservation Blueprint footer logo"  
              class="pt-3 opacity-10 w-7/12 block mx-auto">
      </div>
  </div>

</template>
    
<script>
  import blueprint_logo_footer_svg 
                  from "../assets/img/logos/blueprint_logo_footer.svg"

  export default {

    name: 'BlueprintOptions',

    props:['map', 'parcel_on_click'],

    data(){

      return{
        blueprint_logo_footer_svg,
        model_status: true,
        openspace_status: false,
        parcel_click: this.parcel_on_click || false,
      }
    },

    emits: ['enable_featues', 'include_openspace', 'parcel_on_click'],

    watch:{
      // This updates parcel_click when the prop parcel_on_click is passed down and is a different value. Causes parcel_click to be the same value as parcel_on_click from the parent
      parcel_on_click: function(val){
        this.parcel_click = val;

      },
    },

    methods: {
      toggleModelStatus(){
        this.model_status = !this.model_status;
        this.updateModelStatus();
      },

      toggleOpenspacetatus(){
        this.openspace_status = !this.openspace_status;
        this.updateOpenspaceStatus();
      },

      toggleParcelStatus(){
        this.parcel_click = !this.parcel_click;
        this.updateParcelClick();
      },

      updateModelStatus(){
        this.$emit('enable_featues', this.model_status);
      },

      updateOpenspaceStatus(){
        this.$emit('include_openspace', this.openspace_status);
      },

      updateParcelClick(){
        this.$emit('parcel_on_click', this.parcel_click);
      },

    },

    mounted(){
    }
  }

</script>
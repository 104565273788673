<template>

<div class="bg-white font-Open+Sans overflow-y-scroll"
        :style="{'height': `${panel_height}px`}">
  <div class="w-full text-center mt-6 text-2xl text-slate-500"
        v-show="Object.keys(saved_parcels).length == 0">
      No saved parcels
  </div>
  <div class="w-full text-left pl-6 mt-6 cursor-pointer"
        v-show="Object.keys(saved_parcels).length > 1">
    <a class="w-full inline pt-4 rounded bg-transparent
                  text-blue-500 hover:text-blue-700
                  text-sm xl:text-xl hover:font-semibold"
            @click="printMultiParcelReport">
      <img class="h-6 w-6 inline"
                alt="Prints a parcel report"
                :src="print_hover_svg"
                @mouseover="swapImage($event.target,print_svg)"
                @mouseleave="swapImage($event.target,
                                            print_hover_svg)">
      Print Parcel Summary Report
    </a>
  </div>
  <div class="grid grid-cols-4 col-span-4 bg-white
              font-Open+Sans text-sm text-gray-700 mt-1 border mx-3
              rounded-md shadow-sm"
        v-show="Object.keys(saved_parcels).length > 0">
    <div class="col-span-4 grid grid-cols-12 h-10 bg-gray-100
                border-b border-gray-200 my-auto
                text-base xl:text-2xl">
      <span class="text-center text-base col-span-1 px-0.5">
      </span>
      <span class="text-center my-auto col-span-5">
        Municipality
      </span>
      <span class="text-center my-auto col-span-3">
        Block
      </span>
      <span class="text-center my-auto col-span-3">
        Lot <span class="text-gray-500 text-sm xl:text-lg">(Q)</span>
      </span>
    </div>

    <div class="col-span-4 grid grid-cols-12 my-0.5
                border-b border-gray-200"
          v-for="(value, key) in saved_parcels"
          :key="key">
      <span class="text-center text-base col-span-1 px-0.5
                  border-r border-gray-200">
          <span class="mx-auto cursor-pointer"
                @click="restoreFeature(value.feature_id)"
                title="Go to Feature">
          <img class="h-6 w-6 xl:w-8 xl:h-8"
               :src="view_hover_svg"
               alt="Restores a parcel to the map" 
                @mouseover="swapImage($event.target,view_svg)"
                @mouseleave="swapImage($event.target,
                                              view_hover_svg)">
          </span>
          <span class="mx-auto cursor-pointer"
                title="Print Feature Report"
                @click="printFeature(
                            {'feature_id':value.feature_id})">
          <img class="h-6 w-6 xl:w-8 xl:h-8"
              :src="print_svg"
               alt="Prints a Conservation Blueprint parcel report" 
                @mouseover="swapImage($event.target,print_svg)"
                @mouseleave="swapImage($event.target,
                                            print_hover_svg)">
          </span>
          <span class="mx-auto cursor-pointer"
                title="Remove from Bookmarks">
          <img class="h-6 w-6 xl:w-8 xl:h-8"
               alt="Removes a parcel from the saved parcel list." 
              :src="bookmark_remove_hover_svg"
                @mouseover="swapImage($event.target,
                                    bookmark_remove_svg)"
                @mouseleave="swapImage($event.target,
                                    bookmark_remove_hover_svg)"
                @click="unsaveFeature(
                              {'feature_id':value.feature_id})">
          </span>
      </span>
      <span class="text-left pl-2 text-sm xl:text-xl my-auto col-span-5">
        {{value.muni}}
      </span>
      <span class="text-center text-sm xl:text-xl my-auto col-span-3">
        <span v-if="value.block">{{value.block}}</span>
      </span>
      <span class="text-center text-sm xl:text-xl my-auto col-span-3">
        <span v-if="value.lot">{{value.lot}}</span>
        <span v-if="value.qual"
              class="text-gray-500">
          &nbsp;({{value.qual}})
        </span>
      </span>
    </div>
  </div>
  <div class="pt-4 bg-white text-center">
    <span class="text-base text-cbp opacity-20">
      engage. protect. restore.
    </span>
    <img :src="blueprint_logo_footer_svg"
          alt="Conservation Blueprint footer logo" 
          class="pt-3 opacity-10 w-7/12 block mx-auto">
  </div>
</div>

</template>


<script>

  import blueprint_logo_footer_svg from 
                                "../assets/img/logos/blueprint_logo_footer.svg";
  import bookmark_remove_svg from "../assets/img/icons/bookmark_remove.svg"
  import bookmark_remove_hover_svg from
                                "../assets/img/icons/bookmark_remove_hover.svg"
  import print_svg from "../assets/img/icons/print_hover.svg"
  import print_hover_svg from "../assets/img/icons/print.svg"
  import view_svg from "../assets/img/icons/view.svg"
  import view_hover_svg from "../assets/img/icons/view_hover.svg"

  export default {

    name: 'BookmarkComponent',

    emits: ['print', 'print_multi_parcel', 
            'restore', 'unsave'],

    props: ['panel_height', 'saved_parcels'],

    data() {
      return {

        blueprint_logo_footer_svg,
        bookmark_remove_svg,
        bookmark_remove_hover_svg,
        print_svg,
        print_hover_svg,
        view_svg,
        view_hover_svg
      }
    },

    methods:{

      printFeature(data){
        this.$emit('print', data);
      },

      printMultiParcelReport(){
        this.$emit('print_multi_parcel');
      },

      restoreFeature(id){
        this.$emit('restore', id);
      },

      /**
       * Sets an image tags src attribute to the provided source.
       * @param {Object} element HTML Image element
       * @param {string} src
       */
      swapImage(element,src){
        element.src = src;
      },

      unsaveFeature(data){
        this.$emit('unsave', data);
      },
    }
  }
</script>